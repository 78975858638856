import { ChevronLeftIcon } from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import EditFAQ from "../../components/line-pages/edit/EditFAQ";
import EditSections from "../../components/line-pages/edit/EditSections";
import EditSEO from "../../components/line-pages/edit/EditSEO";
import EditStatistics from "../../components/line-pages/edit/EditStatistics";
import { getLinePageById } from "../../services/LinePages.service";

const EditLinePage = () => {
 const { id, tab = "sections" } = useParams<{ id: string; tab?: string }>();
 const navigate = useNavigate();
 const [linePage, setLinePage] = useState<{
   fromName: string;
   toName: string;
 } | null>(null);

 const handleTabChange = (
   newTab: "sections" | "statistics" | "faq" | "seo"
 ) => {
   navigate(`/line-pages/edit/${id}/${newTab}`);
 };

  useEffect(() => {
    const fetchLinePage = async () => {
      if (id) {
        const response = await getLinePageById(id);
        setLinePage(response.data);
      }
    };
    fetchLinePage().catch(console.error);
  }, [id]);

  return (
    <div>
      <Link
        to="/line-pages"
        className="flex items-center mb-4 text-sm font-semibold text-primary"
      >
        <ChevronLeftIcon className="w-4 h-4 mr-2" />
        Kthehu tek linjat
      </Link>
      <div className="border rounded-lg p-6">
        <h1 className="text-xl mb-2">
          {`Modifiko faqen ${linePage?.fromName ?? ""} - ${
            linePage?.toName ?? ""
          }`}
        </h1>
        <div className="flex gap-4 mb-3 w-full">
          <button
            type="button"
            className={`p-1 ${
              tab === "sections" ? "border-b-primary border-b-2" : ""
            }`}
            onClick={() => handleTabChange("sections")}
          >
            Seksionet
          </button>
          <button
            type="button"
            className={`p-1 ${
              tab === "statistics" ? "border-b-primary border-b-2" : ""
            }`}
            onClick={() => handleTabChange("statistics")}
          >
            Statistikat
          </button>
          <button
            type="button"
            className={`p-1 ${
              tab === "faq" ? "border-b-primary border-b-2" : ""
            }`}
            onClick={() => handleTabChange("faq")}
          >
            FAQ
          </button>
          <button
            type="button"
            className={`p-1 ${
              tab === "seo" ? "border-b-primary border-b-2" : ""
            }`}
            onClick={() => handleTabChange("seo")}
          >
            SEO
          </button>
        </div>
        <div>
          {tab === "sections" && <EditSections />}
          {tab === "statistics" && <EditStatistics />}
          {tab === "faq" && <EditFAQ />}
          {tab === "seo" && <EditSEO />}
        </div>
      </div>
    </div>
  );
};

export default EditLinePage;
