import { useFieldArray, useFormContext } from "react-hook-form";
import { ILinePageSectionEditDto } from "../../../interfaces/endpoints/ILinePage";
import Input from "../../ui/input/Input";
import LinePageTextEditorTranslate from "./LinePageTextEditorTranslate";

interface MultipleLangFieldsLinePageProps {
  sectionIndex: number;
  langIdActive: 1 | 2;
}

const MultipleLangFieldsLinePage: React.FC<MultipleLangFieldsLinePageProps> = ({
  sectionIndex,
  langIdActive
}) => {
  const {
    control,
    register,
    formState: { errors }
  } = useFormContext<{ sections: ILinePageSectionEditDto[] }>();

  const { fields } = useFieldArray({
    control,
    name: `sections.${sectionIndex}.locales`,
    keyName: "uuid"
  });

  return (
    <>

      <div className="overflow-hidden">
        <div
          className={`transform duration-500 transition-all w-full flex ${
            langIdActive === 1 ? "translate-x-0" : "-translate-x-full"
          }`}
        >
          {fields.map((field, index) => (
            <div
              key={field.uuid}
              className={`w-full flex-none p-[1px] duration-500 editor-container-${field.languageId} ${
                langIdActive === field.languageId ? "opacity-100" : "opacity-0"
              }`}
            >
              {/* Title Field */}
              <Input
                {...register(`sections.${sectionIndex}.locales.${index}.title`)}
                label="Titulli"
                className="my-6"
                placeholder="Shkruaj titullin për këtë seksion..."
                error={
                  errors.sections?.[sectionIndex]?.locales?.[index]?.title
                    ?.message
                }
              />

              {/* Content Field */}
              <LinePageTextEditorTranslate
                control={control}
                name={`sections.${sectionIndex}.locales.${index}.content`}
                error={
                  errors.sections?.[sectionIndex]?.locales?.[index]?.content
                    ?.message
                }
                languageId={field.languageId}
              />
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default MultipleLangFieldsLinePage;
