import { PencilSquareIcon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";
import HeaderPage from "../../../components/lines-managements/header/HeaderPage";
import Table, { Row } from "../../../components/ui/table";
import Pagination from "../../../components/ui/table/Pagination";
import Can from "../../../components/users/permissions/Can";
import usePageIndex from "../../../hooks/usePageIndex";
import { useSearch } from "../../../hooks/useSearch";
import { getOperators } from "../../../services/GTFS.service";

const COLUMNS = ["Operator ID", "GjAl Token", "Blog ID", "Veprimet"];
const PAGE_SIZE = 10;

const GTFSOperators: React.FC = () => {
  const { response, loading, handlePageChange, page } = useSearch(getOperators, {
    withQuerySearch: true
  });

  const { pageIndex } = usePageIndex();

  return (
    <>
      <HeaderPage/>
      <div className="flex flex-col" style={{ height: "calc(100vh - 11rem)" }}>
        <Table titles={COLUMNS} style="hover" loading={loading}>
          {!loading && response?.data?.data?.length ? (
            response.data.data.map((row) => (
              <Row
                key={row.id}
                id={row.id}
                gjAlToken={row.gjAlToken}
                blogId={row.blogId}
                actions={
                  <Can permissions="gtfs.update">
                    <Link
                      className="p-2.5 md:p-1"
                      state={{
                        crumbs: ["Menaxhimi i GTFS", "Operatorët", "Ndrysho Operatorin"],
                        page: pageIndex }}
                      to={`edit/${row.id}`}
                    >
                      <PencilSquareIcon className="w-5 h-5 md:w-4" />
                    </Link>
                  </Can>
                }
              />
            ))
          ) : (
            <tr>
              <td colSpan={COLUMNS.length} className="text-center py-4">
                {loading ? "Duke u ngarkuar..." : "Nuk u gjet asnjë operator"}
              </td>
            </tr>
          )}
        </Table>
        <Pagination
          onPageChange={handlePageChange}
          totalCount={response?.data?.totalCount || 0}
          length={response?.data?.data?.length || 0}
          page={page}
          pageSize={PAGE_SIZE}
        />
      </div>
    </>
  );
};

export default GTFSOperators;
