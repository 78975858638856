import React, { useState } from "react";
import AsyncSelect from "react-select/async";
import { ILocation } from "../../interfaces/endpoints/ILocations";
import { searchLocations } from "../../services/Locations.service";

interface AutoSuggestProps {
  placeholder: string;
  onChange: (selectedId: string) => void;
}

const AutoSuggest: React.FC<AutoSuggestProps> = ({ placeholder, onChange }) => {
  const [selectedLocation, setSelectedLocation] = useState<ILocation | null>(null);

  const loadOptions = async (inputValue: string) => {
    try {
      const response = await searchLocations({
        searchKeyword: inputValue || "",
        pageIndex: 0,
        pageSize: 10
      });

      return response.data.map((location: ILocation) => ({
        value: location.id,
        label: location.name
      }));
    } catch (error) {
      console.error("Error fetching locations:", error);
      return [];
    }
  };

  // Handle selection change
  const handleChange = (selectedOption: { value: number; label: string } | null) => {
    setSelectedLocation(selectedOption ? { id: selectedOption.value, name: selectedOption.label } : null);
    onChange(selectedOption ? selectedOption.value.toString() : "");
  };

  return (
    <AsyncSelect
      className="absolute z-50 w-full"
      cacheOptions
      loadOptions={loadOptions}
      onChange={handleChange}
      placeholder={placeholder}
      defaultOptions
      isClearable
      value={
        selectedLocation
          ? { value: selectedLocation.id, label: selectedLocation.name }
          : null
      }
    />
  );
};

export default AutoSuggest;
