/* eslint-disable max-lines */
import { ChevronLeftIcon } from "@heroicons/react/24/outline";
import { yupResolver } from "@hookform/resolvers/yup";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { Link, useNavigate } from "react-router-dom";
import * as yup from "yup";
import Bus from "../../../../components/shared/svgs/Bus";
import FormWrapper from "../../../../components/ui/FormWrapper";
import Input from "../../../../components/ui/input/Input";
import Select from "../../../../components/ui/select/Select";
import SpinnerLoader from "../../../../components/ui/SpinnerLoader";
import { IGTFSStationForm } from "../../../../interfaces/endpoints/IGTFS.types";
import { ILocation } from "../../../../interfaces/endpoints/ILocations";
import { createGTFSStation } from "../../../../services/GTFS.service";
import { getAllLocations } from "../../../../services/Locations.service";

const schema = yup.object().shape({
  id: yup.object().shape({
    id: yup.mixed().required("Stacioni është i detyrueshëm")
  }),
  name: yup.string().required("Emri është i detyrueshëm"),
  latitude: yup.number().required("Gjerësia është e detyrueshme")
    .min(-90, "Gjerësia duhet të jetë më e madhe se -90")
    .max(90, "Gjerësia duhet të jetë më e vogël se 90"),
  longitude: yup.number().required("Gjatësia është e detyrueshme")
    .min(-180, "Gjatësia duhet të jetë më e madhe se -180")
    .max(180, "Gjatësia duhet të jetë më e vogël se 180")
});

const CreateGTFSStation: React.FC = () => {
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isSubmitSuccessful, dirtyFields }
  } = useForm<IGTFSStationForm>({
    resolver: yupResolver(schema),
    mode: "all",
    defaultValues: {
      name: "",
      latitude: undefined,
      longitude: undefined,
      id: undefined
    }
  });

  const onSubmit = async (data: IGTFSStationForm) => {
    setIsSubmitting(true);
    try {
      if (!data.id.id) {
        throw new Error("Stacioni është i detyrueshëm");
      }

      const submitData = {
        id: data.id.id,
        name: data.name,
        latitude: data.latitude,
        longitude: data.longitude
      };

      const response = await createGTFSStation(submitData);

      if (response.status !== 200 && response.status !== 201) {
        toast.error((response as any).message, { duration: 3500 });
        return;
      }

      toast.success("Stacioni u krijua me sukses");
      setTimeout(() => {
        navigate("/gtfs/stations", { state: ["Menaxhimi i GTFS", "Stacionet"] });
      }, 500);

    } catch (error) {
      toast.error("Krijimi i stacionit dështoi. Ju lutem kontrolloni të dhënat dhe provoni përsëri.");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <Link
        to="/gtfs/stations"
        state={["Menaxhimi i GTFS", "Stacionet"]}
        className="flex items-center mb-4 text-primary"
      >
        <ChevronLeftIcon className="w-4 h-4 mr-2" />
        Kthehu tek lista
      </Link>
      <FormWrapper
        showPrompt={
          !isSubmitSuccessful &&
          !!Object.keys(dirtyFields).length
        }
        title="Shto GTFS Stacion"
        onSubmit={handleSubmit(onSubmit)}>
        {isSubmitting ? (
          <div className="flex justify-center items-center py-8">
            <SpinnerLoader />
          </div>
        ) : (
          <div className="grid gap-6">
            <Controller
              control={control}
              name="id"
              render={({ field: { onChange, value } }) => (
                <Select
                  label="Zgjedhni stacionin*"
                  placeholder="Zgjedh stacionin"
                  selectedItem={value as ILocation}
                  asyncFunc={getAllLocations}
                  onSelectedItemChange={(item) => onChange(item)}
                  getOptionLabel={(o) => (
                    <span className="flex items-center">
                      <Bus size={18} />
                      <span className="ml-2">{o.name}</span>
                    </span>
                  )}
                  itemToString={(o) => o?.name || ""}
                  error={errors.id?.message}
                />
              )}
            />
            <Input
              label="Emri*"
              placeholder="Shkruani emrin"
              error={errors.name?.message}
              {...register("name")}
            />
            <Input
              label="Gjerësia*"
              type="number"
              step="any"
              placeholder="Shkruani gjerësinë gjeografike të stacionit"
              error={errors.latitude?.message}
              {...register("latitude")}
            />
            <Input
              label="Gjatësia*"
              type="number"
              step="any"
              placeholder="Shkruani gjatësinë gjeografike të stacionit"
              error={errors.longitude?.message}
              {...register("longitude")}
            />
          </div>
        )}
      </FormWrapper>
    </>
  );
};

export default CreateGTFSStation;
