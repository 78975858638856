/* eslint-disable max-lines */
import { ChevronLeftIcon } from "@heroicons/react/24/outline";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { Link, useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import FormWrapper from "../../../../components/ui/FormWrapper";
import Input from "../../../../components/ui/input/Input";
import SpinnerLoader from "../../../../components/ui/SpinnerLoader";
import { IGTFSStation } from "../../../../interfaces/endpoints/IGTFS.types";
import { getGTFSStation, updateGTFSStation } from "../../../../services/GTFS.service";

const schema = yup.object().shape({
  name: yup.string().required("Emri është i detyrueshëm"),
  latitude: yup.number().required("Gjerësia është e detyrueshme")
    .min(-90, "Gjerësia duhet të jetë më e madhe se -90")
    .max(90, "Gjerësia duhet të jetë më e vogël se 90"),
  longitude: yup.number().required("Gjatësia është e detyrueshme")
    .min(-180, "Gjatësia duhet të jetë më e madhe se -180")
    .max(180, "Gjatësia duhet të jetë më e vogël se 180")
});

const EditGTFSStation: React.FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting, isSubmitSuccessful, dirtyFields }
  } = useForm<IGTFSStation>({
    resolver: yupResolver(schema),
    mode: "all",
    defaultValues: {
      id: undefined,
      name: "",
      latitude: undefined,
      longitude: undefined
    }
  });

  useEffect(() => {
    const loadStation = async () => {
      try {
        setIsLoading(true);
        const response = await getGTFSStation(Number(id));
        reset(response.data);
      } catch (error) {
        toast.error("Ngarkimi i stacionit dështoi");
        navigate("/gtfs/stations");
      } finally {
        setIsLoading(false);
      }
    };

    if (id) {
      void loadStation();
    }
  }, [id, navigate, reset]);

  const onSubmit = async (data: IGTFSStation) => {
    try {
      const response = await updateGTFSStation({ ...data, id: Number(id) });

      if (response.status !== 200 && response.status !== 201) {
        toast.error((response as any).message, { duration: 3500 });
        return;
      }

      toast.success("Stacioni u ndryshua me sukses");
      setTimeout(() => {
        navigate("/gtfs/stations", { state: ["Menaxhimi i GTFS", "Stacionet"] });
      }, 500);

    } catch (error) {
      toast.error("Ndryshimi i stacionit dështoi. Ju lutem kontrolloni të dhënat dhe provoni përsëri.");
      console.error("Error updating station:", error);
    }
  };

  return (
    <>
      <Link
        to="/gtfs/stations"
        state={["Menaxhimi i GTFS", "Stacionet"]}
        className="flex items-center mb-4 text-primary"
      >
        <ChevronLeftIcon className="w-4 h-4 mr-2" />
        Kthehu tek lista
      </Link>
      <FormWrapper
         showPrompt={
          !isSubmitSuccessful &&
          !!Object.keys(dirtyFields).length
        }
        title="Ndrysho GTFS Stacionin"
        onSubmit={handleSubmit(onSubmit)}>
        {isLoading || isSubmitting ? (
          <div className="flex justify-center items-center py-8">
            <SpinnerLoader />
          </div>
        ) : (
          <div className="grid gap-6">
            <Input
              label="Emri*"
              placeholder="Shkruani emrin"
              error={errors.name?.message}
              {...register("name")}
            />
            <Input
              label="Gjerësia*"
              type="number"
              step="any"
              placeholder="Shkruani gjerësinë gjeografike të stacionit të nisjes"
              error={errors.latitude?.message}
              {...register("latitude")}
            />
            <Input
              label="Gjatësia*"
              type="number"
              step="any"
              placeholder="Shkruani gjatësinë gjeografike të stacionit të nisjes"
              error={errors.longitude?.message}
              {...register("longitude")}
            />
          </div>
        )}
      </FormWrapper>
    </>
  );
};

export default EditGTFSStation;
