import { PencilSquareIcon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";
import AuditAuthor from "../../../components/audit-author/AuditAuthor";
import HeaderPage from "../../../components/lines-managements/header/HeaderPage";
import DeleteModal from "../../../components/ui/DeleteModal";
import Table, { Row } from "../../../components/ui/table";
import Pagination from "../../../components/ui/table/Pagination";
import Can from "../../../components/users/permissions/Can";
import useCan from "../../../components/users/permissions/useCan";
import usePageIndex from "../../../hooks/usePageIndex";
import { useSearch } from "../../../hooks/useSearch";
import { IGTFSLine } from "../../../interfaces/endpoints/IGTFS.types";
import { deleteLine, getLines } from "../../../services/GTFS.service";

const COLUMNS: (string | number)[] = [
  "Id",
  "Emri linjes",
  "Emri i shape",
  "Operatori",
  "Oraret aktive ne GTFS",
  "Detajet",
  "Veprimet"
];

const PAGE_SIZE = 10;

const GTFSLines: React.FC = () => {
  const can = useCan("gtfs.create");
  const { response, loading, handlePageChange, page, setResponse } =
    useSearch(getLines, {
      withQuerySearch: true
    });

  const { pageIndex } = usePageIndex();

  return (
    <>
      <HeaderPage
        {...(can && {
          link: {
            to: "manage",
            children: "Shto linje te re",
            state: ["Menaxhimi i GTFS", "Linjat", "Menaxho"]
          }
        })}
      />
      <div className="flex flex-col" style={{ height: "calc(100vh - 11rem)" }}>
        <Table titles={COLUMNS} style="hover" loading={loading}>
          {!loading && response?.data?.result?.length ? (
            response.data.result.map((row: IGTFSLine) => (
              <Row
                key={row.id}
                id={row.id}
                lineName={row.lineName}
                shapeName={row.gtfsShape.name}
                operatorName={row.operatorName}
                activeGtfsSchedules={row.gtfsTrips.length}
                auditAuthor={
                <AuditAuthor
                  createdByName={row.auditAuthor.createdByName}
                  createdByLastName={row.auditAuthor.createdByLastName}
                  updatedByName={row.auditAuthor.updatedByName}
                  updatedByLastName={row.auditAuthor.updatedByLastName}
                  insertDate={row.auditAuthor.insertDate}
                  updateDate={row.auditAuthor.updateDate}
                />}
                actions={
                  <span className="flex items-center">
                    <Can permissions="gtfs.update">
                      <Link
                        className="p-2.5 md:p-1"
                        state={{ crumbs: ["Menaxhimi i GTFS", "Linjat", "Menaxho"],
                          page: pageIndex }}
                        to={`manage?lineId=${row.id}`}
                      >
                        <PencilSquareIcon className="w-5 h-5 md:w-4" />
                      </Link>
                    </Can>
                    <Can permissions="gtfs.delete">
                      <DeleteModal
                        params={row.id}
                        title="Confirm line deletion"
                        handleDelete={deleteLine}
                        successMessage="Line deleted successfully"
                        errorMessage="Failed to delete line"
                        loadingMessage="Deleting line..."
                        onSuccess={() => {
                          setResponse((prev) =>
                            prev ? {
                              ...prev,
                              data: {
                                ...prev.data,
                                result: prev.data.result.filter(
                                  (el: { id: number }) => el.id !== row.id
                                )
                              }
                            } : null
                          );
                        }}
                      />
                    </Can>
                  </span>
                }
              />
            ))
          ) : (
            <tr>
              <td colSpan={COLUMNS.length} className="text-center py-4">
                {loading ? "Loading..." : "No lines found"}
              </td>
            </tr>
          )}
        </Table>
        <Pagination
          onPageChange={handlePageChange}
          totalCount={response?.data?.totalCount || 0}
          length={response?.data?.result?.length || 0}
          page={page}
          pageSize={PAGE_SIZE}
        />
      </div>
    </>
  );
};

export default GTFSLines;
