import LinePagesTable from "../../components/line-pages";
import HeaderPage from "../../components/lines-managements/header/HeaderPage";

const LinePages: React.FC = () => {
  return (
    <>
      <HeaderPage hasNoInput={true}/>
      <LinePagesTable />
    </>
  );
};

export default LinePages;
