import { PencilSquareIcon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";
import HeaderPage from "../../../components/lines-managements/header/HeaderPage";
import DeleteModal from "../../../components/ui/DeleteModal";
import Table, { Row } from "../../../components/ui/table";
import Pagination from "../../../components/ui/table/Pagination";
import Can from "../../../components/users/permissions/Can";
import { useSearch } from "../../../hooks/useSearch";
import { deleteCustomDate, getCustomDates } from "../../../services/TripReservability.service";

const COLUMNS = [
  "ID e orarit",
  "Linja",
  "Numri i datave të rezervueshme",
  "Koha e nisjes",
  "Koha e mbërritjes",
  "Veprimet"
];

const PAGE_SIZE = 10;

const TripReservability: React.FC = () => {

  const { response, loading, handlePageChange, page, setResponse } = useSearch(getCustomDates, {
    withQuerySearch: true
  });

  return (
    <>
      <HeaderPage
        link={{
          to: "manage",
          children: "Shto datë të re",
          state: ["Menaxhimi i linjave", "Datat specifike të rezervueshme", "Menaxho"]
        }}
      />
      <div className="flex flex-col" style={{ height: "calc(100vh - 11rem)" }}>
        <Table titles={COLUMNS} style="hover" loading={loading}>
          {!loading && response?.data?.result?.length ? (
            response.data.result.map((row) => (
              <Row
                key={row.tripId}
                id={row.tripId}
                lineName={row.lineName}
                count={row.count}
                departureTime={row.tripDepartureTime}
                arrivalTime={row.tripArrivalTime}
                actions={
                  <span className="flex items-center">
                    <Can permissions="route.trip.update">
                      <Link
                        className="p-2.5 md:p-1"
                        state={{ crumbs: ["Menaxhimi i linjave",
                           "Datat specifike të rezervueshme", "Menaxho"] }}
                        to={`manage?id=${row.tripId}`}
                      >
                        <PencilSquareIcon className="w-5 h-5 md:w-4" />
                      </Link>
                    </Can>
                    <Can permissions="route.trip.delete">
                      <DeleteModal
                        params={{ tripId: row.tripId }}
                        title="Confirm date deletion"
                        handleDelete={async ({ tripId }) => await deleteCustomDate(tripId)}
                        successMessage="Date deleted successfully"
                        errorMessage="Failed to delete date"
                        loadingMessage="Deleting date..."
                        onSuccess={() => {
                          setResponse((prev) => prev ? {
                            ...prev,
                            data: {
                              ...prev.data,
                              result: prev.data.result.filter((el) => el.tripId !== row.tripId)
                            }
                          } : null);
                        }}
                      />
                    </Can>
                  </span>
                }
              />
            ))
          ) : (
            <tr>
              <td colSpan={COLUMNS.length} className="text-center py-4">
                {loading ? "Loading..." : "No custom dates found"}
              </td>
            </tr>
          )}
        </Table>
        <Pagination
          onPageChange={handlePageChange}
          totalCount={response?.data?.totalCount || 0}
          length={response?.data?.result?.length || 0}
          page={page}
          pageSize={PAGE_SIZE}
        />
      </div>
    </>
  );
};

export default TripReservability;
