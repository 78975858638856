import { useState } from "react";
import { useFormContext } from "react-hook-form";
import { IQuestionAnswerLocalizedDto } from "../../../interfaces/endpoints/IFAQ";
import Localizations from "../../bundles/Localizations";
import Input from "../../ui/input/Input";

const FAQCreator = () => {
  const [langIdActive, setLangIdActive] = useState<1 | 2>(1);
  const {
    register,
    formState: { errors }
  } = useFormContext<IQuestionAnswerLocalizedDto>();

  return (
    <div className="mb-6">
      <Localizations
        activeId={langIdActive}
        onChange={(langId) => setLangIdActive(langId)}
        alError={!!errors.localizedQuestions || !!errors.localizedAnswers}
        enError={!!errors.localizedQuestions || !!errors.localizedAnswers}
      />
      <div className="overflow-hidden">
        <div
          className={`transform duration-500 transition-all w-full flex ${
            langIdActive === 1 ? "translate-x-0" : "-translate-x-full"
          }`}
        >
          {[0, 1].map((langIndex) => (
            <div
              key={langIndex}
              className={`w-full flex-none p-[1px] duration-500 ${
                langIdActive === langIndex + 1 ? "opacity-100" : "opacity-0"
              }`}
            >
              <Input
                {...register(`localizedQuestions.${langIndex}.question`)}
                label="Question"
                className="my-2"
                placeholder="Enter question..."
                error={
                  errors.localizedQuestions?.[langIndex]?.question?.message
                }
              />
              <Input
                {...register(`localizedAnswers.${langIndex}.answer`)}
                label="Answer"
                className="my-2"
                placeholder="Enter answer..."
                error={errors.localizedAnswers?.[langIndex]?.answer?.message}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FAQCreator;
