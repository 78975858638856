import { Control, Controller } from "react-hook-form";
import ReactQuill, { Quill } from "react-quill";

interface LinePageTextEditorTranslateProps {
  control: Control<any>;
  name: any;
  error: string | undefined;
  languageId: number;
}

const LinePageTextEditorTranslate: React.FC<LinePageTextEditorTranslateProps> = ({
  control,
  name,
  error
}) => {
  const Size = Quill.import("attributors/style/size");
  Size.whitelist = ["14px", "16px", "18px"];
  Quill.register(Size, true);

  const modules = {
    toolbar: [
      [{ size: ["14px", "16px", "18px"] }],
      ["bold", "italic", "underline", "blockquote"],
      [{ align: [] }],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link", "image"]
    ]
  };

  return (
    <div>
      <Controller
        name={name}
        control={control}
        render={({ field: { value, onChange } }) => (
          <ReactQuill
            theme="snow"
            className={`text-sm rounded-lg h-auto ${
              error ? "border-primary" : ""
            }`}
            placeholder="Shkruaj përmbajtjen për këtë seksion këtu..."
            value={value}
            onChange={onChange}
            modules={modules}
          />
        )}
      />
      {error && <p className="text-red-500 text-xs mt-0.5">{error}</p>}
    </div>
  );
};

export default LinePageTextEditorTranslate;
