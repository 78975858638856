import axios from "axios";
import { IAxiosResponse } from "../interfaces/IAxiosResponse";
import { IPagination } from "../interfaces/IPagination";
import { ICustomTripReservabilityDate, ICustomTripReservabilityRequest } from "../interfaces/endpoints/ITripReservability.types";
import { serialize } from "../utils/serializeToQueryString";

const BASE_URL = "v1/tripreservability";

export const getCustomDates = async (params: { pageIndex?: number; pageSize?: number; searchKeyword?: string }): Promise<IAxiosResponse<IPagination<ICustomTripReservabilityDate>>> => {
  return axios.get(`${BASE_URL}/customdates${serialize(params)}`);
};

export const getCustomDatesForTrip = async (tripId: number): Promise<IAxiosResponse<string[]>> => {
  return axios.get(`${BASE_URL}/customdates/${tripId}`);
};

export const upsertCustomDates = async (data: ICustomTripReservabilityRequest): Promise<IAxiosResponse<ICustomTripReservabilityDate>> => {
  return axios.post(`${BASE_URL}/upsertcustomdates`, data);
};

export const deleteCustomDate = async (tripId: number): Promise<IAxiosResponse<boolean>> => {
  return axios.delete(`${BASE_URL}/customdate`, { params: { tripId } });
};
