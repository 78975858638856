import axios from "axios";
import { IReview } from "../interfaces/endpoints/IReview";
import { IAxiosResponse } from "../interfaces/IAxiosResponse";
import { IPagination } from "../interfaces/IPagination";
import { serialize } from "../utils/serializeToQueryString";

export type getAllReviewsType = {
  pageIndex: number;
  pageSize: number;
};

export const getAllReviews = async (
  att: getAllReviewsType
): Promise<IAxiosResponse<IPagination<IReview>>> => {
  return axios.get<IPagination<IReview>>(`Review/Reviews${serialize(att)}`);
};

export const changeReviewStatus = async (id: number): Promise<IAxiosResponse<IReview>> => {
  return axios.put(`Review/ChangeReviewStatus/${id}`);
};
