/* eslint-disable max-lines */
import { ChevronLeftIcon } from "@heroicons/react/24/outline";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";
import * as yup from "yup";
import Bus from "../../../../components/shared/svgs/Bus";
import FormWrapper from "../../../../components/ui/FormWrapper";
import Input from "../../../../components/ui/input/Input";
import Select from "../../../../components/ui/select/Select";
import SpinnerLoader from "../../../../components/ui/SpinnerLoader";
import useFetch from "../../../../hooks/useFetch";
import { IGTFSShapeForm } from "../../../../interfaces/endpoints/IGTFS.types";
import { ILocation } from "../../../../interfaces/endpoints/ILocations";
import { createShape, getShape, updateShape } from "../../../../services/GTFS.service";
import { getAllLocations, getCurrentStation } from "../../../../services/Locations.service";

const schema = yup.object().shape({
  name: yup.string().required("Emri është i detyrueshëm"),
  stationFromId: yup.mixed()
    .required("Stacioni i nisjes është i detyrueshëm")
    .test("is-station", "Stacioni i nisjes është i detyrueshëm", (value) => {
      return value && value.id;
    }),
  stationToId: yup.mixed()
    .required("Stacioni i arritjes është i detyrueshëm")
    .test("is-station", "Stacioni i arritjes është i detyrueshëm", (value) => {
      return value && value.id;
    }),
  kmlFile: yup.mixed().when("$editMode", {
    is: false,
    then: yup.mixed()
      .required("KML file është i detyrueshëm")
      .test("fileType", "KML file është i detyrueshëm", (value) => {
        if (!value || !value.length) return false;
        return value[0]?.name?.toLowerCase().endsWith(".kml");
      }),
    otherwise: yup.mixed().optional()
  })
});

const CreateGTFSShape: React.FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const editMode = !!id;
  const [searchParams] = useSearchParams();
  const lineId = searchParams.get("lineId");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { response, loading } = useFetch(getShape, {
    initRequest: editMode,
    params: Number(id)
  });

  const methods  = useForm<IGTFSShapeForm>({
    resolver: yupResolver(schema),
    context: { editMode },
    mode: "all",
    defaultValues: {
      name: "",
      stationFromId: undefined,
      stationToId: undefined
    }
  });

  const { register,
    handleSubmit,
    control,
    reset,
    formState: { errors }
  } = methods;

  useEffect(() => {
    if(editMode && response && response.data.shapeId.endsWith("-reverse"))
      navigate("/gtfs/shapes", { state: ["Menaxhimi i GTFS", "Shapes"] });
  }, [editMode, response]);

  useEffect(() => {
    if (editMode && response && response.data && !loading) {
      // First set the name
      reset({ name: response.data.name });

      // Fetch and set station data
      const fetchStations = async () => {
        try {
          const fromStation = await getCurrentStation(response.data.stationFromId);
          const toStation = await getCurrentStation(response.data.stationToId);
          reset((prev) => ({
            ...prev,
            stationFromId: {
              id: fromStation.data.id,
              name: fromStation.data.stationName
            },
            stationToId: {
              id: toStation.data.id,
              name: toStation.data.stationName
            }
          }), { keepDefaultValues: true });
        } catch (error) {
          toast.error("Failed to load station details.");
        }
      };

      void fetchStations();
    }
  }, [response]);

  const onSubmit = async (formData: IGTFSShapeForm) => {
    setIsSubmitting(true);
    try {
      if (!formData.stationFromId?.id || !formData.stationToId?.id) {
        throw new Error("Stations are required");
      }

      const submitFormData = new FormData();
      submitFormData.append("name", formData.name);
      submitFormData.append("stationFromId", formData.stationFromId.id.toString());
      submitFormData.append("stationToId", formData.stationToId.id.toString());

      if (formData.kmlFile && formData.kmlFile.length > 0) {
        submitFormData.append("kmlFile", formData.kmlFile[0]);
      }
      let submitResponse;
      if (editMode) {
        submitResponse = await updateShape(response?.data.id || 0, submitFormData);
      } else {
        submitResponse = await createShape(submitFormData);
      }

      if (submitResponse.status !== 200 && submitResponse.status !== 201) {
        toast.error((submitResponse as any).message, { duration: 5000 });
        return;
      }

      toast.success(`Shape ${editMode ? "updated" : "created"} successfully`);
      if (lineId) {
        setTimeout(() => {
          navigate(`/gtfs/lines/manage?lineId=${lineId}`,
             { state: ["Menaxhimi i GTFS", "Linjat", "Menaxho"] });
        }, 500);
      } else {
        setTimeout(() => {
          navigate("/gtfs/shapes", { state: ["Menaxhimi i GTFS", "Shapes"] });
        }, 500);
      }

    } catch (error) {
      toast.error(`Dështoj ${editMode ? "ndryshimi i " : "krijimi i "}
        shape. Ju lutem kontrolloni stacionet, kml dhe provoni perseri.`);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <Link
        to="/gtfs/shapes"
        state={["Menaxhimi i GTFS", "Shapes"]}
        className="flex items-center mb-4 text-primary">
        <ChevronLeftIcon className="w-4 h-4 mr-2" />
        Kthehu tek lista
      </Link>

      <FormProvider  {...methods}>
        <FormWrapper
          title={`${editMode ? "Ndrysho" : "Shto"} GTFS Shape`}
          showPrompt={
            !methods.formState.isSubmitSuccessful &&
            !!Object.keys(methods.formState.dirtyFields).length
          }
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="grid gap-6">
            <Input
            {...register("name", { required: "Vendos emrin e shape" })}
            label="Emri i shape*"
            error={errors.name?.message}
            placeholder="Shkruani emrin e shape këtu..."
          />
            <Controller
              control={control}
              name="stationFromId"
              render={({ field: { onChange, value } }) => (
                <Select
                  label="Stacioni i nisjes*"
                  placeholder="Zgjedh stacionin e nisjes"
                  selectedItem={value as ILocation}
                  asyncFunc={getAllLocations}
                  onSelectedItemChange={(item) => onChange(item)}
                  getOptionLabel={(o) => (
                    <span className="flex items-center">
                      <Bus size={18} />
                      <span className="ml-2">{o.name}</span>
                    </span>
                  )}
                  itemToString={(o) => o?.name || ""}
                  error={errors.stationFromId?.message}
                />
              )}
            />
            <Controller
              control={control}
              name="stationToId"
              render={({ field: { onChange, value } }) => (
                <Select
                  label="Stacioni i arritjes*"
                  placeholder="Zgjedh stacionin e arritjes"
                  selectedItem={value as ILocation}
                  asyncFunc={getAllLocations}
                  onSelectedItemChange={(item) => onChange(item)}
                  getOptionLabel={(o) => (
                    <span className="flex items-center">
                      <Bus size={18} />
                      <span className="ml-2">{o.name}</span>
                    </span>
                  )}
                  itemToString={(o) => o?.name || ""}
                  error={errors.stationToId?.message}
                />
              )}
            />
              <div>
                {!editMode && (
                  <p className="text-sm text-neutral mb-1.5">KML File*</p>
                )}
                <input
                  type="file"
                  placeholder="Choose a KML file"
                  accept=".kml"
                  {...register("kmlFile")}
                  className={`w-full p-2 border rounded ${errors.kmlFile ? "border-red-500" : ""}`}
                />
                {errors.kmlFile && (
                  <span className="text-red-500 text-sm block mt-1">
                    {errors.kmlFile.message}
                  </span>
                )}
                <p className="text-sm text-gray-500 mt-1">
                  Klikoni <a
                  className="underline"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.google.com/maps/d/?hl=en">këtu</a> për të krijuar KML file
                </p>
                {editMode && (
                  <p className="text-sm text-gray-500 mt-1">
                    Ngarko KML vetëm nëse dëshiron të zëvendësosh atë ekzistues
                  </p>
                )}
              </div>

            {isSubmitting && (
              <div className="flex flex-col justify-center items-center h-full min-h-[100px]">
                <p className="mb-4 text-gray-500 text-center">
                Duke procesuar. Kjo mund të zgjasë pak.
                </p>
                <SpinnerLoader />
              </div>
            )}
          </div>
        </FormWrapper>
      </FormProvider>
    </>
  );
};

export default CreateGTFSShape;
