/* eslint-disable max-lines */
import { ChevronLeftIcon } from "@heroicons/react/24/outline";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { Link, useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import FormWrapper from "../../../../components/ui/FormWrapper";
import Input from "../../../../components/ui/input/Input";
import Select from "../../../../components/ui/select/Select";
import SpinnerLoader from "../../../../components/ui/SpinnerLoader";
import { IGTFSOperator } from "../../../../interfaces/endpoints/IGTFS.types";
import { getBlogsSelectList, getCurrentBlog } from "../../../../services/Blogs.service";
import { getOperator, updateOperator } from "../../../../services/GTFS.service";

const schema = yup.object().shape({
  gjAlToken: yup.string().required("GjAl Token është i detyrueshëm"),
  blogId: yup.object().shape({
    id: yup.number(),
    title: yup.string()
  }).required("Blogu është i detyrueshëm")
});

interface FormValues extends Omit<IGTFSOperator, "blogId"> {
  blogId: { id: number; title: string };
}

const EditGTFSOperator: React.FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);

  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors, isSubmitting, dirtyFields, isSubmitSuccessful }
  } = useForm<FormValues>({
    resolver: yupResolver(schema),
    mode: "all",
    defaultValues: {
      gjAlToken: "",
      blogId: undefined
    }
  });

  useEffect(() => {
    const loadOperator = async () => {
      try {
        setIsLoading(true);
        const response = await getOperator(Number(id));

        if (response.data.blogId !== 0) {
          try {
            const blogResponse = await getCurrentBlog(response.data.blogId);
            reset({
              gjAlToken: response.data.gjAlToken,
              blogId: {
                id: response.data.blogId,
                title: blogResponse.data.title
              }
            });
          } catch (error) {
            reset({
              gjAlToken: response.data.gjAlToken,
              blogId: {
                id: response.data.blogId,
                title: `Blog ${response.data.blogId}`
              }
            });
            toast.error("Dështoi ngarkimi i detajeve të blogut");
          }
        } else {
          reset({
            gjAlToken: response.data.gjAlToken,
            blogId: undefined
          });
        }
      } catch (error) {
        toast.error("Dështoi ngarkimi i operatorit");
        navigate("/gtfs/operators");
      } finally {
        setIsLoading(false);
      }
    };

    if (id) {
      void loadOperator();
    }
  }, [id, navigate, reset]);

  const onSubmit = async (data: FormValues) => {
    try {
      const response = await updateOperator({
        id: Number(id),
        gjAlToken: data.gjAlToken,
        blogId: data.blogId.id
      });

      if (response.status !== 200 && response.status !== 201) {
        toast.error((response as any).message, { duration: 3500 });
        return;
      }

      toast.success("Operatori u ndryshua me sukses");
      setTimeout(() => {
        navigate("/gtfs/stations", { state: ["Menaxhimi i GTFS", "Stacionet"] });
      }, 500);

    } catch (error) {
      toast.error("Ndryshimi i operatorit dështoi. Ju lutem kontrolloni të dhënat dhe provoni përsëri.");
      console.error("Error updating operator:", error);
    }
  };

  return (
    <>
      <Link
        to="/gtfs/operators"
        state={["Menaxhimi i GTFS", "Operatorët"]}
        className="flex items-center mb-4 text-primary"
      >
        <ChevronLeftIcon className="w-4 h-4 mr-2" />
        Kthehu tek lista
      </Link>
      <FormWrapper
        showPrompt={
              !isSubmitSuccessful &&
              !!Object.keys(dirtyFields).length
            }
        title="Ndrysho GTFS Operatorin" onSubmit={handleSubmit(onSubmit)}>
        {isLoading || isSubmitting ? (
          <div className="flex justify-center items-center py-8">
            <SpinnerLoader />
          </div>
        ) : (
          <div className="grid gap-6">
            <Input
              label="GjAl tokeni*"
              placeholder="Shkruani GjAl tokenin"
              error={errors.gjAlToken?.message}
              {...register("gjAlToken")}
            />
            <Controller
              control={control}
              name="blogId"
              render={({ field: { onChange, value } }) => (
                <Select
                  label="Blogu*"
                  placeholder="Zgjedhni blogun"
                  selectedItem={value}
                  onSelectedItemChange={onChange}
                  error={errors.blogId?.message}
                  asyncFunc={getBlogsSelectList}
                  getOptionLabel={(o) => `${o.id} - ${o.title}`}
                  itemToString={(o) => o ? `${o.id} - ${o.title}` : ""}
                />
              )}
            />
          </div>
        )}
      </FormWrapper>
    </>
  );
};

export default EditGTFSOperator;
