/* eslint-disable max-lines */
import {
  PaperAirplaneIcon,
  StarIcon,
  XCircleIcon
} from "@heroicons/react/24/outline";
import dayjs from "dayjs";
import { useState } from "react";
import { Link } from "react-router-dom";
import usePageIndex from "../../hooks/usePageIndex";
import { useSearch } from "../../hooks/useSearch";
import { IReview } from "../../interfaces/endpoints/IReview";
import {
  changeReviewStatus,
  getAllReviews
} from "../../services/Reviews.service";
import DeleteModal from "../ui/DeleteModal";
import IconBtn from "../ui/IconBtn";
import Table, { Row } from "../ui/table";
import Pagination from "../ui/table/Pagination";
import Can from "../users/permissions/Can";
import ReviewModal from "./ReviewModal";

const columns: (string | number)[] = [
  "Unique ID",
  "Emri i linjes",
  "Operatori",
  "Rating",
  "Komenti",
  "Statusi",
  "Data e vlersimit",
  "Veprimet"
];

const ReviewTable = () => {
  const { response, loading, handlePageChange, page, setResponse } = useSearch(
    getAllReviews,
    { withQuerySearch: true, withFilter: true, filterSelector: "rating" }
  );

  const [showReviewModal, setShowReviewModal] = useState(false);
  const [selectedReview, setSelectedReview] = useState<IReview | null>(null);

  const { state }: any = usePageIndex();
  const handleRowClick = (row: IReview) => {
    setSelectedReview(row); // Set the selected review
    setShowReviewModal(true); // Open the modal
  };

  return (
    <div className="flex flex-col" style={{ height: "calc(100vh - 11rem)" }}>
      <Table titles={columns} style="hover" loading={loading}>
        {response?.data.result?.length ? (
          response?.data.result?.map((row) => {
            const highlighted = +state?.params?.id === row.id;
            return (
              <Row
                key={row.id}
                className={`${highlighted && "highlighted"}`}
                uniqueJourneyId={
                  <Link
                    className="text-blue-mainText"
                    to={`/ticket/reservations/edit/${row.uniqueJourneyId}`}
                  >
                    {row.uniqueJourneyId}
                  </Link>
                }
                lineName={row.lineName}
                operator={row.operatorName}
                rating={
                  <div className="flex font-medium items-center">
                    <StarIcon fill="#f15b2e" className="w-5 h-5 text-primary font-bold mr-1" />{" "}
                    ({row.rating})
                  </div>
                }
                comment={
                  <div className="flex">
                    {row.comment?.slice(0, 30)}
                    {(row.comment?.length > 30 || row.improvementPossibilities.length > 0) && (
                      <div>
                        ...
                        <button
                          className="underline"
                          onClick={() => handleRowClick(row)}
                        >
                          Show more
                        </button>
                      </div>
                    )}
                  </div>
                }

                status={
                  row.isActive ? (
                    <p className="text-green text-sm">Live</p>
                  ) : (
                    <p className="text-red text-sm">Joaktiv</p>
                  )
                }
                insertDate={dayjs(row.insertDate).format("YYYY/MM/DD")}
                // inserDate={row.inserDate}
                actions={
                  <span className="flex">
                    <Can permissions="reviews.review.update">
                      <DeleteModal
                        params={row.id}
                        title={
                          row.isActive
                            ? "Konfirmo largimin e vlersimit."
                            : "Konfirmo aprovimin e vlersimit."
                        }
                        handleDelete={changeReviewStatus}
                        successMessage={
                          row.isActive
                            ? "Vlersimi është larguar me sukses."
                            : "Vlersimi është aprovuar me sukses."
                        }
                        errorMessage={
                          row.isActive
                            ? "Vlersimi nuk është larguar me sukses."
                            : "Vlersimi nuk është aprovuar me sukses."
                        }
                        loadingMessage={
                          row.isActive
                            ? "Vlersimi është duke u larguar..."
                            : "Vlersimi është duke u aprovuar..."
                        }
                        indicator={
                          row.isActive ? (
                            <IconBtn>
                              <XCircleIcon className="text-red w-6 h-6" />
                            </IconBtn>
                          ) : (
                            <div className="hover:bg-gray-100 hover:cursor-pointer rounded-md p-1">
                              <div className="flex items-center">
                                <PaperAirplaneIcon className="text-primary w-6 h-6 -rotate-45" />
                                <p className="text-sm">Post Live</p>
                              </div>
                            </div>
                          )
                        }
                        onSuccess={() => {
                          setResponse((prev) =>
                            prev
                              ? {
                                  ...prev,
                                  data: {
                                    ...prev.data,
                                    result: prev.data.result.map((el) =>
                                      el.id === row.id
                                        ? { ...el, isActive: !el.isActive }
                                        : el
                                    )
                                  }
                                }
                              : null
                          );
                        }}
                      />
                    </Can>
                  </span>
                }
              />
            );
          })
        ) : (
          <></>
        )}
      </Table>
      <Pagination
        onPageChange={handlePageChange}
        totalCount={response?.data?.totalCount || 0}
        length={response?.data?.result.length}
        page={page}
        pageSize={10}
      />
      {selectedReview && (
        <ReviewModal
          show={showReviewModal}
          onClose={() => setShowReviewModal(false)}
          stars={selectedReview.rating}
          comment={selectedReview.comment}
          improvementPossibilities={selectedReview.improvementPossibilities}
        />
      )}
    </div>
  );
};
export default ReviewTable;
