import HeaderPage from "../../components/lines-managements/header/HeaderPage";
import ReviewTable from "../../components/reviews";

const Reviews: React.FC = () => {
  return (
    <>
      <HeaderPage
        hasFilter
        filterName="Filtro Reviews"
        filterSelctor="rating"
        options={[
          { label: "★★★★★", id: "5" },
          { label: "★★★★", id: "4" },
          { label: "★★★", id: "3" },
          { label: "★★", id: "2" },
          { label: "★", id: "1" }
        ]}
      />
      <ReviewTable />
    </>
  );
};

export default Reviews;
