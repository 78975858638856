import { StarIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { IImprovementPossibilities } from "../../interfaces/endpoints/IReview";
import Modal from "../ui/Modal";

interface IReviewModal {
  show: boolean;
  onClose: () => void;
  stars: number;
  comment: string;
  improvementPossibilities: IImprovementPossibilities[];
}

const ReviewModal: React.FC<IReviewModal> = ({
  show,
  onClose,
  stars,
  comment,
  improvementPossibilities
}) => {
  return (
    <Modal
      position="center"
      show={show}
      onClose={onClose}
      mini={false}
      hasHeader={false}
      calcHeight={true}
    >
      <div className="flex justify-between items-center border-b p-4">
        <h2 className="text-lg font-semibold">Review Details</h2>
        <XMarkIcon
          className="w-6 h-6 text-neutral cursor-pointer"
          onClick={onClose}
        />
      </div>
      <div className="mt-4 flex flex-col items-center">
        <div className="flex mb-2">
          {[...Array(5)].map((_, index) => (
            <StarIcon
              key={index}
              fill={index < stars ? "#f15b2e" : "#ffffff"} // Conditionally set the fill color
              className="w-5 h-5 text-primary font-bold mr-1"
            />
          ))}
        </div>
        <p className="text-gray-600 text-sm text-center p-5 italic mb-5">
          {comment}
        </p>
        {improvementPossibilities.length ? (
          <p className="mb-2">Improvement possiblities</p>
        ) : (
          <></>
        )}
        <div className="flex flex-wrap justify-center mb-5 text-center">
          {improvementPossibilities.map((item, index) => (
            <div
              key={index}
              className="p-2 m-1 rounded-full bg-gradient-to-r from-primary to-pink text-white text-xs"
            >
              {item.name}
            </div>
          ))}
        </div>
      </div>
    </Modal>
  );
};

export default ReviewModal;
