/* eslint-disable max-lines */
import { ChevronLeftIcon, PlusIcon } from "@heroicons/react/24/outline";
import { yupResolver } from "@hookform/resolvers/yup";
import cx from "classnames";
import { useEffect, useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import * as yup from "yup";
import GTFSLineTripsRow from "../../../../components/gtfs/GTFSLineTripsRow";
import FormWrapper from "../../../../components/ui/FormWrapper";
import Select from "../../../../components/ui/select/Select";
import SpinnerLoader from "../../../../components/ui/SpinnerLoader";
import Table from "../../../../components/ui/table";
import useUser from "../../../../hooks/useUser";
import { IGTFSLineCreateForm, IGTFSLineUpsertForm } from "../../../../interfaces/endpoints/IGTFS.types";
import { ITripType } from "../../../../interfaces/endpoints/trips/Trips.types";
import { getLineDetails, getShapesSelectList, upsertGTFSLine } from "../../../../services/GTFS.service";
import { getCurrentLine, getLinesSelectList } from "../../../../services/Lines.service";
import { getTripsByLine } from "../../../../services/Trips.service";

const schema = yup.object().shape({
  lineId: yup.mixed()
    .required("Linja është e detyrueshme")
    .test("has-value", "Linja është e detyrueshme", (value) => {
      return value && value.value;
    }),
  shapeId: yup.mixed()
    .required("Shape është i detyrueshëm")
    .test("has-id", "Shape është i detyrueshëm", (value) => {
      return value && value.id;
    }),
  trips: yup.object().test("at-least-one-trip", "Duhet të zgjidhni të paktën një orar",
    (obj: Record<string, { selected: boolean }>) => {
      return obj && Object.values(obj).some((val) => val.selected);
    })
});

const ManageGTFSLine: React.FC = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const lineId = searchParams.get("lineId");
  const [trips, setTrips] = useState<ITripType[]>([]);
  const [isLoadingTrips, setIsLoadingTrips] = useState(false);
  const { user } = useUser();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const methods = useForm<IGTFSLineCreateForm>({
    resolver: yupResolver(schema),
    mode: "all",
    defaultValues: {
      lineId: undefined,
      shapeId: undefined,
      trips: {}
    }
  });

  const { control, watch, handleSubmit, setValue } = methods;
  const selectedLine = watch("lineId");

  const handleLineSelection = async (selectedLineId: number) => {
    setIsLoadingTrips(true);
    try {
      const tripsResponse = await getTripsByLine(selectedLineId);
      const reservableTrips = tripsResponse.data
      .filter((trip) => trip.trip.isReservable && trip.trip.operationalIntervalId !== 54);
      setTrips(reservableTrips);

      const tripsFormData: { [key: string]: { id: number; selected: boolean } } = {};
      reservableTrips.forEach((trip) => {
        tripsFormData[`trip_${trip.trip.id}`] = {
          id: trip.trip.id,
          selected: false
        };
      });

      try {
        const response = await getLineDetails(selectedLineId);
        if (response.data.gtfsShape) {
          setValue("shapeId", {
            id: response.data.gtfsShape.id,
            name: response.data.gtfsShape.name
          });
        }

        // Update trip selections only if we have line details
        if (response.data.gtfsTrips) {
          Object.keys(tripsFormData).forEach((key) => {
            const tripId = tripsFormData[key].id;
            tripsFormData[key].selected = response.data.gtfsTrips.some(
              (gt) => gt.id === tripId
            );
          });
        }
      } catch (error) {
        // Keep default false selections for trips
      }

      methods.reset({
        ...methods.getValues(),
        trips: tripsFormData
      }, { keepValues: true });
    } finally {
      setIsLoadingTrips(false);
    }
  };

  useEffect(() => {
    if (lineId) {
      setIsLoadingTrips(true);
      getCurrentLine(parseInt(lineId))
        .then((response) => {
          setValue("lineId", {
            value: response.data.id,
            label: response.data.lineName,
            parentValue: response.data.operatorId,
            oldValue: 0,
            selected: true
          });
          return handleLineSelection(parseInt(lineId));
        })
        .catch((error) => {
          toast.error("Dështoi ngarkimi i të dhënave të linjës");
          console.error("Error handling line selection:", error);
        }).finally(() => {
          setIsLoadingTrips(false);
        });
    }
  }, [lineId, setValue]);

  useEffect(() => {
      if (selectedLine?.value && !lineId) {
        handleLineSelection(Number(selectedLine.value))
      .then(() => {
        // Do nothing
      })
      .catch((error) => {
        toast.error("Dështoi ngarkimi i të dhënave të linjës");
        console.error("Error handling line selection:", error);
      });
    }
  }, [selectedLine, searchParams]);

  const onSubmit = async (data: IGTFSLineCreateForm) => {
    setIsSubmitting(true);
    try {
      if (!data.lineId?.value || !data.shapeId?.id) {
        toast.error("Ju lutem plotësoni të gjitha fushat e detyrueshme");
        return;
      }

      const submitData: IGTFSLineUpsertForm = {
        lineId: Number(data.lineId.value),
        shapeId: Number(data.shapeId.id),
        tripIds: Object.values(data.trips)
          .filter((trip) => trip.selected)
          .map((trip) => trip.id)
      };

      if (submitData.tripIds.length === 0) {
        toast.error("Ju lutem zgjidhni të paktën një orar");
        return;
      }

      const response = await upsertGTFSLine(submitData);

      if (response.status !== 200 && response.status !== 201) {
        toast.error((response as any).message, { duration: 3500 });
        return;
      }

      toast.success("GTFS Linja u ruajt me sukses");
      setTimeout(() => {
        navigate(`/gtfs/operators/edit/${data.lineId?.parentValue}`,
           { state: ["Menaxhimi i GTFS", "Operatorët", "Ndrysho Operatorin"] });
      }, 500);
    } catch (error) {
      toast.error("Dështoi ruajtja e GTFS Linjës. Ju lutem kontrolloni të dhënat dhe provoni përsëri.");
      console.error("Error saving GTFS Line:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <Link to="/gtfs/lines" state={["Menaxhimi i GTFS", "Linjat"]}
       className="flex items-center mb-4 text-primary">
        <ChevronLeftIcon className="w-4 h-4 mr-2" />
        Kthehu tek lista
      </Link>
      <FormProvider {...methods} >
        <FormWrapper
          showPrompt={
            !methods.formState.isSubmitSuccessful &&
            !!Object.keys(methods.formState.dirtyFields).length
          }
          title="Menaxhimi i GTFS Line"
          onSubmit={handleSubmit(onSubmit)}
          showSaveBtn={!isLoadingTrips}
        >
            <div className="grid gap-6">
              <Controller
                control={control}
                name="lineId"
                render={({ field: { onChange, value } }) => (
                  <Select
                    label={`Linja* (duke të treguar vetëm linjat e operatorit të zgjedhur -
                      ${user?.operator.operatorName})`}
                    placeholder="Zgjedh linjen"
                    selectedItem={value}
                    onSelectedItemChange={onChange}
                    error={methods.formState.errors.lineId?.message}
                    asyncFunc={getLinesSelectList}
                    getOptionLabel={(o) => `${o.value} - ${o.label}`}
                    itemToString={(o) => o ? `${o.value} - ${o.label}` : ""}
                    className="mb-6"
                  />
                )}
              />
              <Controller
                control={control}
                name="shapeId"
                render={({ field: { onChange, value } }) => (
                  <div className="flex gap-2 mb-6">
                    <div className="flex-1">
                      <Select
                        label="Shape*"
                        placeholder="Zgjedh shape"
                        selectedItem={value}
                        onSelectedItemChange={onChange}
                        error={methods.formState.errors.shapeId?.message}
                        asyncFunc={getShapesSelectList}
                        getOptionLabel={(o) => `${o.id} - ${o.name}`}
                        itemToString={(o) => o ? `${o.id} - ${o.name}` : ""}
                      />
                    </div>
                    <Link
                      to={`/gtfs/shapes/create${selectedLine?.value? `?lineId=${selectedLine.value}`:""}`}
                      className={cx([
                        "h-10 mt-[23px]",
                        "flex justify-between items-center sm:px-4 px-3 leading-[18px] whitespace-nowrap",
                        "font-bold duration-300 text-sm",
                        "bg-primary text-white rounded-lg group hover:shadow-xl"
                      ])}
                    >
                      <span className="relative">
                        <PlusIcon
                          strokeWidth={3}
                          className={cx([
                            "w-4 h-4",
                            "absolute group-hover:translate-y-4",
                            "translate-y-0 opacity-100 group-hover:opacity-0",
                            "transition-all duration-300",
                            "sm:mr-1"
                          ])}
                        />
                        <PlusIcon
                          strokeWidth={3}
                          className={cx([
                            "w-4 h-4",
                            "group-hover:translate-y-0 -translate-y-4 opacity-0 group-hover:opacity-100",
                            "transition-all duration-300",
                            "sm:mr-1"
                          ])}
                        />
                      </span>
                      <span className="hidden sm:inline whitespace-nowrap">
                        Shto Shape
                      </span>
                    </Link>
                  </div>
                )}
              />
              <div>
              <p className="text-sm text-neutral mb-1.5">Oraret*</p>
                {isLoadingTrips ? (
                  <div className="flex justify-center items-center py-8">
                    <SpinnerLoader />
                  </div>
                ) : trips.length === 0 ? (
                  <div className="text-center py-8 text-gray-500">
                    Ju lutem zgjedhni një linjë
                  </div>
                ) : (
                  <Table
                    titles={["ID e orarit", "Koha Nisjes", "Koha Arritjes", "Aktive"]}
                    loading={isLoadingTrips}
                    className="overflow-x-auto"
                    thClassName="py-3 px-6"
                  >
                    {trips.map((trip) => (
                      <GTFSLineTripsRow
                        key={trip.trip.id}
                        trip={trip.trip}
                        name={`trips.trip_${trip.trip.id}`}
                        control={control}
                      />
                    ))}
                  </Table>
                )}
                {methods.formState.errors.trips && (
                  <p className="text-red-500 text-sm mt-1">
                    {methods.formState.errors.trips.message?.toString()}
                  </p>
                )}
              </div>
            </div>
            {isSubmitting && (
            <div className="mt-8 mb-4 flex justify-center items-center animate-pulse">
              <p className="text-gray-500 text-center">
                Duke procesuar. Ju lutem prisni...
              </p>
              <SpinnerLoader className="ml-8"/>
            </div>
          )}
        </FormWrapper>
      </FormProvider>
    </>
  );
};

export default ManageGTFSLine;
