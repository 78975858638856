import { TrashIcon } from "@heroicons/react/24/outline";
import { useEffect } from "react";
import {
  FormProvider,
  SubmitHandler,
  useFieldArray,
  useForm
} from "react-hook-form";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import useFetch from "../../../hooks/useFetch";
import { ILinePageSectionEditDto } from "../../../interfaces/endpoints/ILinePage";
import { editLinePageSection, getLinePageSections } from "../../../services/LinePages.service";
import FormWrapper from "../../ui/FormWrapper";
import IconBtn from "../../ui/IconBtn";
import SpinnerLoader from "../../ui/SpinnerLoader";
import SectionCreator from "./SectionCreator";

const EditSections = () => {
  const { id } = useParams<{ id: string }>();

  const methods = useForm<{ sections: ILinePageSectionEditDto[] }>({
    defaultValues: { sections: [] }
  });

  const { control, handleSubmit, reset, watch } = methods;
  const { append } = useFieldArray({ control, name: "sections" });
  const { response ,loading ,refetch } = useFetch(() => getLinePageSections(Number(id)), {
    initRequest: true
  });

  useEffect(() => {
    if (response?.data) {
      reset({ sections: response.data });
    }
  }, [response, reset]);

  const sections = watch("sections");

  const handleDeleteSection = (sectionId: number, index: number) => {
    const updatedSections =
      sectionId === 0
        ? sections.filter((_, i) => i !== index)
        : sections.filter((section) => section.id !== sectionId);
    reset({ sections: updatedSections });
  };

  const onSubmit: SubmitHandler<{
    sections: ILinePageSectionEditDto[];
  }> = async (data) => {
    try {
      await editLinePageSection(id ?? "", data.sections);
      toast.success("Linja është përditësuar me sukses.", {
        position: "top-right"
      });
      refetch();
    } catch {
      toast.error("Ndodhi një gabim gjatë përditësimit.", {
        position: "top-right"
      });
    }
  };

  return (
    <FormProvider {...methods}>
      <FormWrapper
        onSubmit={handleSubmit(onSubmit)}
        title="Modifiko Seksionet e Linjës"
        showPrompt={
          !methods.formState.isSubmitSuccessful &&
          !!Object.keys(methods.formState.dirtyFields).length
        }
      >
        <div className="min-h-[600px] flex items-center justify-center">
          {loading ? (
            <SpinnerLoader className="text-primary" />
          ) : (
            <div className="w-full">
              {sections.map((section, index) => (
                <div
                  className="w-full flex flex-row gap-2"
                  key={section.id || index}
                >
                  <SectionCreator section={section} sectionIndex={index} />
                  <IconBtn
                    className="w-10 h-10 text-neutral select-none"
                    onClick={() => handleDeleteSection(section.id, index)}
                  >
                    <TrashIcon className="w-6 h-6 " />
                  </IconBtn>
                </div>
              ))}
              <div className="flex flex-row justify-center">
                <button
                  type="button"
                  className="p-2 font-semibold bg-primary text-white rounded-xl"
                  onClick={() =>
                    append({
                      id: 0,
                      linePageId: Number(id),
                      displayOrder: 0,
                      locales: [
                        { languageId: 1, title: "", content: "" },
                        { languageId: 2, title: "", content: "" }
                      ],
                      sectionPictureMapping: []
                    })
                  }
                >
                  Shto Seksion
                </button>
              </div>
            </div>
          )}
        </div>
      </FormWrapper>
    </FormProvider>
  );
};

export default EditSections;
