/* eslint-disable max-lines */
import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { ILinePageSectionEditDto } from "../../../interfaces/endpoints/ILinePage";
import Localizations from "../../bundles/Localizations";
import DoubleImageContent from "../../shared/svgs/DoubleImageContent";
import DoubleImageIcon from "../../shared/svgs/DoubleImageIcon";
import ImageContent from "../../shared/svgs/ImageContent";
import SingleImageIcon from "../../shared/svgs/SingleImage";
import TitleText from "../../shared/svgs/TitleText";
import LinePageSectionImage from "./LinePageSectionImage";
import MultipleLangFieldsLinePage from "./MultipleLangFieldsLinePage";

interface SectionCreatorProps {
  section: ILinePageSectionEditDto;
  sectionIndex: number;
}

export const getSectionType = (
  sectionModel: ILinePageSectionEditDto
): string => {
  const hasTitleAndContent = sectionModel.locales.some(
    (locale) => locale.title && locale.content
  );

  if (sectionModel.sectionPictureMapping?.length === 2 && hasTitleAndContent)
    return "DOUBLE_IMAGE_CONTENT";
  if (sectionModel.sectionPictureMapping?.length === 1 && hasTitleAndContent)
    return "IMAGE_CONTENT";
  if (hasTitleAndContent) return "CONTENT";
  if (sectionModel.sectionPictureMapping?.length === 2) return "DOUBLE_IMAGE";
  if (sectionModel.sectionPictureMapping?.length === 1) return "SINGLE_IMAGE";
  return "CONTENT";
};

const SectionCreator: React.FC<SectionCreatorProps> = ({
  section,
  sectionIndex
}) => {
  const { setValue, getValues } = useFormContext();
  const [selectedType, setSelectedType] = useState<string | null>(null);
  const [langIdActive, setLangIdActive] = useState<1 | 2>(2);

  useEffect(() => {
    console.log(section, "section");
    if (section.id > 0 && !selectedType) {
      setSelectedType(getSectionType(section));
    }
  }, [section, selectedType]);

  const handleTypeChange = (type: string) => {
    setSelectedType(type);

    const initialLocales = [
      { languageId: 1, title: "", content: "" },
      { languageId: 2, title: "", content: "" }
    ];

    setValue(`sections.${sectionIndex}.locales`, initialLocales);
    setValue(`sections.${sectionIndex}.sectionPictureMapping`, []);

    // Set displayOrder if it's a new section
    if (!getValues(`sections.${sectionIndex}.displayOrder`)) {
      setValue(`sections.${sectionIndex}.displayOrder`, sectionIndex);
    }
  };

  if (!selectedType) {
    return (
      <div className="flex flex-col items-center mb-6 p-4 border rounded-md bg-gray-50">
        <h4 className="text-lg mb-4 font-medium text-gray-600">
          Choose Section Type
        </h4>
        <div className="flex flex-wrap justify-center gap-4">
          {[
            {
              type: "IMAGE_CONTENT",
              label: "Image & Content",
              Icon: ImageContent
            },
            {
              type: "DOUBLE_IMAGE_CONTENT",
              label: "Double Image & Content",
              Icon: DoubleImageContent
            },
            {
              type: "CONTENT",
              label: "Content",
              Icon: TitleText
            },
            {
              type: "SINGLE_IMAGE",
              label: "Single Image",
              Icon: SingleImageIcon
            },
            {
              type: "DOUBLE_IMAGE",
              label: "Double Image",
              Icon: DoubleImageIcon
            }
          ].map(({ type, label, Icon }) => (
            <button
              key={type}
              type="button"
              className={`w-28 h-28 border  rounded-md flex flex-col items-center justify-center p-1
                space-y-2 ${
                  selectedType === type ? "bg-primary text-white" : "bg-white"
                }`}
              onClick={() => handleTypeChange(type)}
            >
              <Icon className="w-16 h-16" />
              <span className="text-sm font-medium text-gray-600">{label}</span>
            </button>
          ))}
        </div>
      </div>
    );
  }

  return (
    <div className="mb-6 p-5 border rounded-md w-full">
      <div className="w-full flex items-center justify-center mb-1">
        <Localizations
          activeId={langIdActive}
          onChange={(langId) => setLangIdActive(langId)}
          alError={false}
          enError={false}
        />
      </div>
      <div>
        {[
          "IMAGE_CONTENT",
          "DOUBLE_IMAGE_CONTENT",
          "SINGLE_IMAGE",
          "DOUBLE_IMAGE"
        ].includes(selectedType) && (
          <div
            className={`space-y-4 ${
              selectedType === "DOUBLE_IMAGE_CONTENT" ||
              selectedType === "DOUBLE_IMAGE"
                ? "flex space-y-0 space-x-4"
                : ""
            }`}
          >
            <div className="w-full">
              <LinePageSectionImage
                sectionIndex={sectionIndex}
                imageIndex={0}
                langIdActive={langIdActive}
              />
            </div>
            {(selectedType === "DOUBLE_IMAGE_CONTENT" ||
              selectedType === "DOUBLE_IMAGE") && (
              <div className="w-full max-w-xs aspect-square">
                <LinePageSectionImage
                  sectionIndex={sectionIndex}
                  imageIndex={1}
                  langIdActive={langIdActive}
                />
              </div>
            )}
          </div>
        )}

        {[
          "IMAGE_CONTENT",
          "DOUBLE_IMAGE_CONTENT",
          "CONTENT"
        ].includes(selectedType) && (
          <MultipleLangFieldsLinePage
            sectionIndex={sectionIndex}
            langIdActive={langIdActive}
          />
        )}
      </div>
    </div>
  );
};

export default SectionCreator;
