/* eslint-disable max-lines */
import { useEffect } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import useFetch from "../../../hooks/useFetch";
import { ILinePageStatistics } from "../../../interfaces/endpoints/ILinePage";
import { getLinePageStatistics, updateLinePageStatistics } from "../../../services/LinePages.service";
import FormWrapper from "../../ui/FormWrapper";
import Input from "../../ui/input/Input";
import SpinnerLoader from "../../ui/SpinnerLoader";

const EditStatistics = () => {
  const { id } = useParams<{ id: string }>();

  const methods = useForm<ILinePageStatistics>({
    defaultValues: {
      averageTripDuration: "",
      averageTicketPrice: 0,
      minTripDuration: "",
      maxTripDuration: "",
      minTicketPrice: 0,
      maxTicketPrice: 0,
      earliestBusDeparture: "",
      latestBusDeparture: "",
      dailyBuses: 0,
      distanceInKm: 0,
      distanceInMinutesDriving: 0
    }
  });

  const { reset, handleSubmit } = methods;
  const { response, loading, refetch } = useFetch(() => getLinePageStatistics(Number(id)), {
    initRequest: true
  });

  const onSubmit: SubmitHandler<ILinePageStatistics> = async (data) => {
    try {
      await updateLinePageStatistics(Number(id), data);
      toast.success("Statistics updated successfully.", {
        position: "top-right"
      });
      refetch();
    } catch {
      toast.error("Failed to update statistics.", {
        position: "top-right"
      });
    }
  };

  useEffect(() => {
    if (response?.data) {
      reset(response.data);
    }
  }, [response, reset]);

  return (
    <FormProvider {...methods}>
      <FormWrapper
        onSubmit={handleSubmit(onSubmit)}
        title="Modifiko Statistikat e Linjës"
        showPrompt={
          !methods.formState.isSubmitSuccessful &&
          !!Object.keys(methods.formState.dirtyFields).length
        }
      >
        <div className="min-h-[500px] flex items-center justify-center">
          {loading ? (
            <SpinnerLoader className="text-primary" />
          ) : (
            <div className="flex flex-col w-full">
              <Input
                {...methods.register("averageTripDuration")}
                label="Average Trip Duration"
                className="my-2"
                placeholder="Enter average trip duration..."
                error={methods.formState.errors.averageTripDuration?.message}
              />
              <Input
                type="text"
                {...methods.register("averageTicketPrice")}
                label="Average Ticket Price"
                className="my-2"
                placeholder="Enter average ticket price..."
                error={methods.formState.errors.averageTicketPrice?.message}
              />
              <Input
                {...methods.register("minTripDuration")}
                label="Min Trip Duration"
                className="my-2"
                placeholder="Enter minimum trip duration..."
                error={methods.formState.errors.minTripDuration?.message}
              />
              <Input
                {...methods.register("maxTripDuration")}
                label="Max Trip Duration"
                className="my-2"
                placeholder="Enter maximum trip duration..."
                error={methods.formState.errors.maxTripDuration?.message}
              />
              <Input
                type="number"
                {...methods.register("minTicketPrice")}
                label="Min Ticket Price"
                className="my-2"
                placeholder="Enter minimum ticket price..."
                error={methods.formState.errors.minTicketPrice?.message}
              />
              <Input
                type="number"
                {...methods.register("maxTicketPrice")}
                label="Max Ticket Price"
                className="my-2"
                placeholder="Enter maximum ticket price..."
                error={methods.formState.errors.maxTicketPrice?.message}
              />
              <Input
                {...methods.register("earliestBusDeparture")}
                label="Earliest Bus Departure"
                className="my-2"
                placeholder="Enter earliest bus departure..."
                error={methods.formState.errors.earliestBusDeparture?.message}
              />
              <Input
                {...methods.register("latestBusDeparture")}
                label="Latest Bus Departure"
                className="my-2"
                placeholder="Enter latest bus departure..."
                error={methods.formState.errors.latestBusDeparture?.message}
              />
              <Input
                type="number"
                {...methods.register("dailyBuses")}
                label="Daily Buses"
                className="my-2"
                placeholder="Enter number of daily buses..."
                error={methods.formState.errors.dailyBuses?.message}
              />
              <Input
                type="text"
                {...methods.register("distanceInKm")}
                label="Distance in Km"
                className="my-2"
                placeholder="Enter distance in km..."
                error={methods.formState.errors.distanceInKm?.message}
              />
              <Input
                type="text"
                {...methods.register("distanceInMinutesDriving")}
                label="Distance in Minutes Driving"
                className="my-2"
                placeholder="Enter distance in minutes driving..."
                error={
                  methods.formState.errors.distanceInMinutesDriving?.message
                }
              />
            </div>
          )}
        </div>
      </FormWrapper>
    </FormProvider>
  );
};

export default EditStatistics;
