import { PencilSquareIcon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";
import HeaderPage from "../../../components/lines-managements/header/HeaderPage";
import Table, { Row } from "../../../components/ui/table";
import Pagination from "../../../components/ui/table/Pagination";
import Can from "../../../components/users/permissions/Can";
import useCan from "../../../components/users/permissions/useCan";
import usePageIndex from "../../../hooks/usePageIndex";
import { useSearch } from "../../../hooks/useSearch";
import { getGTFSStations } from "../../../services/GTFS.service";

const COLUMNS = ["ID", "Emri", "Gjerësia", "Gjatësia", "Veprimet"];
const PAGE_SIZE = 10;

const GTFSStations: React.FC = () => {
  const can = useCan("gtfs.create");
  const { response, loading, handlePageChange, page } = useSearch(getGTFSStations, {
    withQuerySearch: true
  });

  const { pageIndex } = usePageIndex();

  return (
    <>
      <HeaderPage {...(can && {
          link: {
            to: "create",
            children: "Shto stacion të ri",
            state: ["Menaxhimi i GTFS", "Stacionet", "Shto"]
          }
        })}/>
      <div className="flex flex-col" style={{ height: "calc(100vh - 11rem)" }}>
        <Table titles={COLUMNS} style="hover" loading={loading}>
          {!loading && response?.data?.result?.length ? (
            response.data.result.map((row) => (
              <Row
                key={row.id}
                id={row.id}
                name={row.name}
                latitude={row.latitude}
                longitude={row.longitude}
                actions={
                  <Can permissions="gtfs.update">
                    <Link
                      className="p-2.5 md:p-1"
                      state={{
                        crumbs: ["Menaxhimi i GTFS", "Stacionet", "Ndrysho"],
                        page: pageIndex
                      }}
                      to={`edit/${row.id}`}
                    >
                      <PencilSquareIcon className="w-5 h-5 md:w-4" />
                    </Link>
                  </Can>
                }
              />
            ))
          ) : (
            <tr>
              <td colSpan={COLUMNS.length} className="text-center py-4">
                {loading ? "Duke u ngarkuar..." : "Nuk u gjet asnjë stacion"}
              </td>
            </tr>
          )}
        </Table>
        <Pagination
          onPageChange={handlePageChange}
          totalCount={response?.data?.totalCount || 0}
          length={response?.data?.result?.length || 0}
          page={page}
          pageSize={PAGE_SIZE}
        />
      </div>
    </>
  );
};

export default GTFSStations;
