/* eslint-disable max-lines */
import { MapIcon, PencilSquareIcon } from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { Link, useLocation, useSearchParams } from "react-router-dom";
import usePageIndex from "../../../hooks/usePageIndex";
import { useSearch } from "../../../hooks/useSearch";
import { ITripType } from "../../../interfaces/endpoints/trips/Trips.types";
import { deleteTrip, getAllTrips } from "../../../services/Trips.service";
import AuditAuthor from "../../audit-author/AuditAuthor";
import Table, { Row } from "../../ui/table";
import Pagination from "../../ui/table/Pagination";
import Can from "../../users/permissions/Can";
import Days from "./Days";
import DeleteTrip from "./DeleteTripModal";
import Prices from "./Prices";

const columns: (string | number)[] = [
  "ID e orarit",
  "Emri i linjës",
  "Nisja",
  "Arritja",
  "Ditët e udhëtimit",
  "Çmimet",
  "Detajet",
  "Veprimet"
];

const Trips = () => {
  const [data, setData] = useState<ITripType[] | undefined>([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const {  state }: any = useLocation();
  const { pageIndex } = usePageIndex();
  const { response, loading } = useSearch(getAllTrips, {
    withQuerySearch: true
  });
  useEffect(() => {
    setData(response?.data?.result);
  }, [response]);

  const handlePageChange = ({ selected }: { selected: number }) => {
    if (selected !== 0) {
      searchParams.set("pageindex", `${selected + 1}`);
    } else searchParams.delete("pageindex");
    setSearchParams(searchParams, { replace: false });
  };
  const handleDelete = (id: number) => {
    deleteTrip(id)
      .then(() => {
        toast.success("Orari është fshirë me sukses.", {
          position: "top-right"
        });
        const deleted = data?.filter((el) => {
          return el.trip.id !== id;
        });
        setData(deleted);
      })
      .catch(() => toast.error("Ndodhi një gabim gjatë fshirjes."));
  };

  return (
      <div className="flex flex-col" style={{ height: "calc(100vh - 11rem)" }}>
      <Table
        style="hover"
        titles={columns}
        loading={loading}
        className="md:table"
      >
        {data?.length ? (
          data?.map((row, i: number) => {
            const highlighted = +state?.params?.id === row.trip.id;
            return (
            <Row
              key={i}
              id={row.trip.id}
              routeName={row.routeName}
              className={`${highlighted && "highlighted"}`}
              departureTimeString={row.trip.departureTimeString}
              arrivalTimeString={row.trip.arrivalTimeString}
              operationalInterval={
                <Days
                  days={[
                    row.operationalInterval.monday,
                    row.operationalInterval.tuesday,
                    row.operationalInterval.wednesday,
                    row.operationalInterval.thursday,
                    row.operationalInterval.friday,
                    row.operationalInterval.saturday,
                    row.operationalInterval.sunday
                  ]}
                />
              }
              prices={<Prices prices={row.prices} />}
              auditAuthor={
              <AuditAuthor
                createdByName={row.auditAuthor.createdByName}
                createdByLastName={row.auditAuthor.createdByLastName}
                updatedByName={row.auditAuthor.updatedByName}
                updatedByLastName={row.auditAuthor.updatedByLastName}
                insertDate={row.auditAuthor.insertDate}
                updateDate={row.auditAuthor.updateDate}
              />}
              actions={
                <span className="flex items-center">
                  <Can permissions="route.trip.update">
                    <Link
                      className="p-2.5 md:p-1"
                      to={`/trips/edit/${row.trip.id}`}
                      state={{ crumbs: [
                        "Informatat për orarin",
                        "Oraret",
                        "Modifiko Orarin"
                      ],
                      page: pageIndex }}
                    >
                      <PencilSquareIcon className="w-5 h-5 md:w-4" />
                    </Link>
                  </Can>
                  <Can permissions="gtfs.read">
                    <Link
                      className={`p-2.5 md:p-1 ${row.hasGTFS && "text-primary"}
                      hover:text-primary/80 transition-colors`}
                      to={`/gtfs/lines/manage?lineId=${row.trip.lineId}`}
                      state={{
                        crumbs: ["Menaxhimi i GTFS", "Linjat", "Menaxho"]
                      }}
                    >
                      <MapIcon className="w-5 h-5 md:w-4" />
                    </Link>
                  </Can>
                  <Can permissions="route.trip.delete">
                    <DeleteTrip
                      index={row.trip.id}
                      handleDelete={() => handleDelete(row.trip.id)}
                    />
                  </Can>
                </span>
              }
            />
          );
})
        ) : (
          <></>
        )}
      </Table>
      <Pagination
        onPageChange={handlePageChange}
        totalCount={(response?.data.totalPages || 0) * 10}
        length={data?.length}
        page={parseInt(`${searchParams.get("pageindex")}`) || 1}
        pageSize={10}
      />
    </div>
  );
};

export default Trips;
