import { array, object, string } from "yup";

export const BlogSchema = object().shape({
  blogPictureMappings: array()
    .nullable()
    .required("Duhet të vendosni një fotografi"),
  // title: string()
  //   .required("Vendos titullin e blogut!")
  //   .max(50, "Titulli për blog duhet të jetë më së shumti 50 karaktere"),
  // description: string().required("Vendos përshkrimin e blogut!"),
  locales: array().of(
    object().shape({
      title: string()
        .required("Vendos titullin!")
        .max(100, "Titulli për blog duhet të jetë më së shumti 100 karaktere")
        .typeError("Vendos titullin!"),
      description: string()
        .required("Vendos përshkrimin!")
        .typeError("Vendos përshkrimin!")
    })
  )
});
