/* eslint-disable max-lines */
import {
  Bars3Icon,
  BriefcaseIcon,
  BuildingStorefrontIcon,
  CircleStackIcon,
  CubeIcon,
  HomeIcon,
  InformationCircleIcon,
  MapIcon,
  MapPinIcon,
  NewspaperIcon,
  StarIcon,
  TagIcon,
  UserGroupIcon,
  UsersIcon
} from "@heroicons/react/24/outline";
import Can from "../../users/permissions/Can";
import SuperAdmin from "../../users/permissions/SuperAdmin";
import Item from "./Item";
import VideoTutorials from "./VideoTutorials";

const Body: React.FC = () => {
  return (
    <>
      <ul className="select-none w-full md:pl-3">
        <SuperAdmin>
          <Item
            crumbs={["Gjirafa Dashboard"]}
            href="/dashboard-gjirafa"
            name="Operator Dashboard"
            icon={<HomeIcon />}
          />
        </SuperAdmin>
        <Item
          crumbs={["Dashboard"]}
          href="/"
          name="Ballina"
          icon={<HomeIcon />}
        />
        <Can permissions="reservation.ticket.read">
          <Item
            crumbs={["Rezervimet"]}
            href="/office"
            name="Rezervimet"
            icon={<BuildingStorefrontIcon />}
          />
        </Can>

        <Can permissions={["reservation.old.ticket.read"]}>
          <Item
            crumbs={["Rezervimet"]}
            name="Menaxhimi i shitjeve"
            icon={<UsersIcon strokeWidth={1} />}
          >
            <Can permissions="reservation.old.ticket.read">
              <Item
                crumbs={["Rezervimet", "Version 2.0"]}
                href="/ticket/reservations"
                name="Rezervimet Online"
                // widget={
                //   <div
                //     className="inline ml-2 bg-primary text-xs shadow-btn transition-all
                // text-white rounded-full px-2 py-1 font-semibold group-hover:saturate-200 duration-300"
                //   >
                //     New
                //   </div>
                // }
                icon={<CircleStackIcon />}
              />
            </Can>
            {/* <Can permissions="reservation.old.ticket.read">
            <Item
              crumbs={["Menaxhimi i shitjeve", "Old Version"]}
              href="/reservations"
              name="Menaxhimi i shitjeve"
              icon={<CircleStackIcon />}
            />
          </Can> */}
          </Item>
        </Can>
        <Can
          permissions={[
            "route.line.read",
            "route.trip.read",
            "pricings.pricing.read",
            "tripnotices.tripnotice.read",
            "route.announcement.read"
          ]}
        >
          <Item
            crumbs={["Menaxhimi i linjave"]}
            name="Menaxhimi i linjave"
            icon={<Bars3Icon />}
          >
            <Can permissions="route.line.read">
              <Item
                crumbs={["Menaxhimi i linjave", "Linjat"]}
                href="/lines"
                name="Linjat"
              />
            </Can>
            <Can permissions="route.trip.read">
              <Item
                crumbs={["Menaxhimi i linjave", "Oraret"]}
                href="/trips"
                name="Oraret"
              />
            </Can>
            <SuperAdmin>
              <Item
                crumbs={["Menaxhimi i linjave", "Datat specifike të rezervueshme"]}
                href="/trip-reservability"
                name="Datat specifike të rezervueshme"
              />
            </SuperAdmin>
            <Can permissions="pricings.pricing.read">
              <Item
                crumbs={["Menaxhimi i linjave", "Çmimet"]}
                href="/prices"
                name="Çmimet"
              />
            </Can>
            <Can permissions="tripnotices.tripnotice.read">
              <Item
                crumbs={["Informata për oraret"]}
                href="/notices"
                name="Informata për oraret"
              />
            </Can>
            <Can permissions="route.announcement.read">
              <Item
                crumbs={["Informatat për linja"]}
                href="/announcements"
                name="Informatat për linja"
                icon={<InformationCircleIcon />}
              />
            </Can>
          </Item>
        </Can>
        <Can
          permissions={[
            "travelbundles.travelbundle.read",
            "travelbundles.travelbundle.reservation.read",
            "travelbundles.travelbundle.include.read",
            "travelbundles.travelbundle.diplayorder.read"
          ]}
        >
          <Item
            crumbs={["Menaxhimi i ofertave"]}
            name="Menaxhimi i ofertave"
            icon={<CubeIcon strokeWidth={1} />}
          >
            <Can permissions="travelbundles.travelbundle.read">
              <Item
                crumbs={["Menaxhimi i ofertave", "Ofertat e udhëtimit"]}
                href="/bundles/manage"
                name="Ofertat e udhëtimit"
              />
            </Can>
            <Can permissions="travelbundles.travelbundle.reservation.read">
              <Item
                crumbs={["Menaxhimi i ofertave", "Rezervimet e ofertave"]}
                href="/bundles/reservations"
                name="Rezervimet e ofertave"
              />
            </Can>
            <Can permissions="travelbundles.travelbundle.diplayorder.read">
              <Item
                crumbs={["Menaxhimi i ofertave", "Renditja e ofertave "]}
                href="/bundles/order"
                name="Renditja e ofertave "
              />
            </Can>
            <Can permissions="travelbundles.travelbundle.include.read">
              <Item
                crumbs={["Menaxhimi i ofertave", "Veçoritë e ofertave"]}
                href="/bundles/features"
                name="Veçoritë e ofertave"
              />
            </Can>
          </Item>
        </Can>
        <Can
          permissions={[
            "userroles.userrole.read",
            "users.user.read",
            "permissions.permission.read"
          ]}
        >
          <Item
            crumbs={["Menaxhimi i roleve"]}
            name="Menaxhimi i roleve"
            icon={<UsersIcon strokeWidth={1} />}
          >
            <Can permissions="userroles.userrole.read">
              <Item
                crumbs={["Menaxhimi i roleve", "Rolet"]}
                href="/users/roles"
                name="Rolet"
              />
            </Can>
            <Can permissions="users.user.read">
              <Item
                crumbs={["User overview", "Users"]}
                href="/users/overview"
                name="User overview"
              />
            </Can>
            <Can permissions="permissions.permission.read">
              <Item
                crumbs={["Access control list"]}
                href="/users/access"
                name="Access control list"
              />
            </Can>
          </Item>
        </Can>
        <Can permissions="blogs.blog.read">
          <Item
            crumbs={["Blogu i udhëtimeve"]}
            href="/blogs"
            name="Blogu"
            icon={<NewspaperIcon />}
          />
        </Can>
        <Can permissions="reviews.review.read">
          <Item
            crumbs={["Reviews"]}
            href="/reviews"
            name="Reviews"
            icon={<StarIcon />}
          />
        </Can>
        <Can permissions="linepages.linepage.read">
          <Item
            crumbs={["Line Pages"]}
            href="/line-pages"
            name="Line Pages"
            icon={<Bars3Icon />}
          />
        </Can>
        <Can permissions="promotioncodes.promotioncode.read">
          <Item
            crumbs={["Kodet promocionale"]}
            href="/promotioncodes"
            name="Kodet promocionale"
            icon={<TagIcon />}
          />
        </Can>
        <Can permissions="operators.operator.read">
          <Item
            crumbs={["Operatorët"]}
            href="/operators"
            name="Operatorët"
            icon={<UserGroupIcon />}
          />
        </Can>
        <Can permissions="locations.station.read">
          <Item
            crumbs={["Stacionet"]}
            href="/locations"
            name="Stacionet"
            icon={<MapPinIcon />}
          />
        </Can>
        <SuperAdmin>
          <Item
            crumbs={["Operatoret", "Ndrysho operatorin"]}
            href="/operator/change"
            name="Ndrysho operatorin"
            icon={<BriefcaseIcon />}
          />
        </SuperAdmin>
        <Can permissions="gtfs.read">
          <Item
            crumbs={["Menaxhimi i GTFS"]}
            name="Menaxhimi i GTFS"
            icon={<MapIcon />}
          >
            <Item
              crumbs={["Menaxhimi i GTFS", "Linjat"]}
              href="/gtfs/lines"
              name="Linjat"
            />
            <Item
              crumbs={["Menaxhimi i GTFS", "Shapes"]}
              href="/gtfs/shapes"
              name="Shapes"
            />
            <Item
              crumbs={["Menaxhimi i GTFS", "Operatorët"]}
              href="/gtfs/operators"
              name="Operatorët"
            />
            <Item
              crumbs={["Menaxhimi i GTFS", "Stacionet"]}
              href="/gtfs/stations"
              name="Stacionet"
            />
            <Can permissions="gtfs.create">
              <Item
                crumbs={["Menaxhimi i GTFS", "Generate Feed"]}
                href="/gtfs/generate-feed"
                name="Generate Feed"
              />
            </Can>
          </Item>
        </Can>
        {/* <SuperAdmin>
          <Item
            crumbs={["Operatoret", "Link email operatorin"]}
            href="/operator/link"
            name="Link email me operatorin"
            icon={<LinkIcon />}
          />
        </SuperAdmin> */}
        {/* <Item
        crumbs={["Financat"]}
        href="/finances"
        name="Financat"
        icon={<CreditCardIcon />}
      /> */}
      </ul>
      <VideoTutorials />
    </>
  );
};

export default Body;
