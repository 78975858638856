/* eslint-disable max-lines */
import { ChevronLeftIcon } from "@heroicons/react/24/outline";
import cx from "classnames";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { toast } from "react-hot-toast";
import { Link } from "react-router-dom";
import FormWrapper from "../../../components/ui/FormWrapper";
import SpinnerLoader from "../../../components/ui/SpinnerLoader";
import Table from "../../../components/ui/table";
import { IGTFSUploadResult } from "../../../interfaces/endpoints/IGTFS.types";
import { generateGTFSFeed } from "../../../services/GTFS.service";

const GTFSFeedGeneration: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [result, setResult] = useState<IGTFSUploadResult | null>(null);
  const methods = useForm();

  const handleGenerateFeed = async () => {
    setIsLoading(true);
    try {
      const response = await generateGTFSFeed();

      if (response.status !== 200 && response.status !== 201) {
        toast.error((response as any).message, { duration: 5000 });
        return;
      }

      setResult(response.data);
      toast.success("GTFS feed u gjenerua me sukses!");
    } catch (error) {
      console.error("Gjenerimi i GTFS feed deshtoi. ", error);
      toast.error("Gjenerimi i GTFS feed deshtoi");
    } finally {
      setIsLoading(false);
    }
  };

  const handleCopyToClipboard = (text: string) => {
    void navigator.clipboard.writeText(text);
    toast.success("Linku u kopjua me sukses!");
  };

  const buttonClassName = cx([
    "inline-flex justify-center items-center px-4 py-2 w-full",
    "font-bold duration-300 text-sm",
    "bg-primary text-white rounded-lg hover:bg-primary-dark hover:shadow-xl"
  ]);

  return (
    <>
      <Link
        to="/gtfs/lines"
        state={["Menaxhimi i GTFS", "Linjat"]}
        className="flex items-center mb-4 text-primary"
      >
        <ChevronLeftIcon className="w-4 h-4 mr-2" />
        Kthehu tek lista e linjave
      </Link>

      <FormProvider {...methods}>
        <FormWrapper
          showPrompt={!!result}
          title="Gjenerimi i GTFS Feed "
          onSubmit={handleGenerateFeed}
        >
          {isLoading ? (
            <div className="flex flex-col justify-center items-center h-full min-h-[200px]">
              <p className="mb-4 text-gray-500 text-center animate-pulse">
                Duke gjeneruar...
              </p>
              <p className="mb-4 text-gray-500 text-center animate-pulse">
                Kjo mund të zgjasë pak. Ju lutem prisni...
              </p>
              <SpinnerLoader />
            </div>
          ) : !result ? (
            <div className="text-center flex justify-center items-center py-8 h-full text-gray-500">
              Kliko butonin "Ruaj ndryshimet" për të gjeneruar GTFS feed
            </div>
          ) : (
            <div className="space-y-6">
              <div>
                <p className="text-sm text-neutral mb-1.5">Ndiqni hapat në vijim për shtimin e GTFS:</p>
                <Table
                  titles={["Përshkrimi", "Veprime"]}
                  loading={false}
                  className="overflow-x-auto"
                  thClassName="py-3 px-6"
                >
                  <tr>
                    <td className="py-2 px-6">Download Feed</td>
                    <td className="py-2 px-6">
                      <Link
                        to={result.feedUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                        className={buttonClassName}
                      >
                        Download
                      </Link>
                    </td>
                  </tr>
                  <tr>
                    <td className="py-2 px-6">Valido Feed</td>
                    <td className="py-2 px-6">
                      <Link
                        to="https://gtfs-validator.mobilitydata.org/"
                        target="_blank"
                        rel="noopener noreferrer"
                        className={buttonClassName}
                      >
                        Vizito Faqen
                      </Link>
                    </td>
                  </tr>
                  <tr>
                    <td className="py-2 px-6">Shto Feed në Google</td>
                    <td className="py-2 px-6">
                      <Link
                        to="https://transitpartners.webapps.google.com/static_pipeline_details
                        /sbcfv7776rrnd5?a=3002845420293455873"
                        target="_blank"
                        rel="noopener noreferrer"
                        className={buttonClassName}
                      >
                        Vizito Faqen
                      </Link>
                    </td>
                  </tr>
                </Table>
              </div>

              {Object.keys(result.gjAlRedirects).length > 0 && (
                <div>
                  <p className="text-sm text-neutral mb-1.5">gj.al linqet që duhen shkurtuar</p>
                  <Table
                    titles={["Token", "URL"]}
                    className="overflow-x-auto"
                    thClassName="py-3 px-6"
                  >
                    {Object.entries(result.gjAlRedirects).map(([token, gjAlRedirect]) => (
                      <tr key={token}>
                        <td
                          className="py-2 px-6 cursor-pointer hover:bg-gray-50"
                          onClick={() => handleCopyToClipboard(token)}
                        >
                          {token}
                        </td>
                        <td
                          className="py-2 px-6 max-w-xl truncate cursor-pointer hover:bg-gray-50"
                          onClick={() => handleCopyToClipboard(gjAlRedirect.url)}
                        >
                          {gjAlRedirect.name}
                        </td>
                      </tr>
                    ))}
                  </Table>
                </div>
              )}
            </div>
          )}
        </FormWrapper>
      </FormProvider>
    </>
  );
};

export default GTFSFeedGeneration;
