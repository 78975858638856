import { FC } from "react";
import { Control, Controller } from "react-hook-form";
import Checkbox from "../../components/ui/input/Checkbox";
import { Row } from "../../components/ui/table";
import { IGTFSLineCreateForm } from "../../interfaces/endpoints/IGTFS.types";
import { ITrip } from "../../interfaces/endpoints/trips/Trips.types";

interface Props {
  trip: ITrip;
  name: `trips.${string}`;
  control: Control<IGTFSLineCreateForm>;
}

const GTFSLineTripsRow: FC<Props> = ({ trip, name, control }) => {
  const cells = {
    [`${trip.id}_trip`]: (
      <td className="w-64 py-2 truncate px-2">{trip.id}</td>
    ),
    [`${trip.id}_departure`]: (
      <td className="px-2">{trip.departureTime}</td>
    ),
    [`${trip.id}_arrival`]: (
      <td className="px-2">{trip.arrivalTime}</td>
    ),
    [`${trip.id}_select`]: (
      <div className="flex justify-center">
        <Controller
          control={control}
          name={name}
          defaultValue={{ id: trip.id, selected: false }}
          render={({ field }) => {
            return (
              <Checkbox
                name={field.name}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  field.onChange({
                    id: trip.id,
                    selected: event.target.checked
                  });
                }}
                checked={field.value?.selected ?? false}
              />
            );
          }}
        />
      </div>
    )
  };

  return <Row key={`trip_${trip.id}`} {...cells} />;
};

export default GTFSLineTripsRow;
