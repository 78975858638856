/* eslint-disable max-lines */
import { ArrowUpOnSquareIcon, XCircleIcon } from "@heroicons/react/24/outline";
import { ChangeEvent, useEffect, useState } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { LoaderIcon } from "react-hot-toast";
import useFetch from "../../../hooks/useFetch";
import { ILinePageSectionEditDto } from "../../../interfaces/endpoints/ILinePage";
import { getGuid, uploadPhoto } from "../../../services/Media.service";
import Input from "../../ui/input/Input";
import useCan from "../../users/permissions/useCan";

interface LinePageImageProps {
  sectionIndex: number;
  imageIndex: number;
  langIdActive: 1 | 2;
}

const LinePageImage: React.FC<LinePageImageProps> = ({
  sectionIndex,
  imageIndex,
  langIdActive
}) => {
  const [imageData, setImageData] = useState<string>();
  const [loadingUpload, setLoadingUpload] = useState<boolean>(false);
  const { response } = useFetch(getGuid, { initRequest: true });
  const {
    register,
    setValue,
    getValues,
    control,
    formState: { errors }
  } = useFormContext<{ sections: ILinePageSectionEditDto[] }>();
  const { fields } = useFieldArray({
    control,
    name: `sections.${sectionIndex}.sectionPictureMapping.${imageIndex}.locales`,
    keyName: "uuid"
  });

  useEffect(() => {
    const section = getValues(`sections.${sectionIndex}`);
    if (section.sectionPictureMapping?.[imageIndex]) {
      setImageData(section.sectionPictureMapping[imageIndex].pictureUrl);
    }
  }, [sectionIndex, imageIndex]);

  const onChangeHandle = async (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files?.length) return;

    setLoadingUpload(true);
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("file", file);

    try {
      const res = await uploadPhoto({
        guid: response?.data.guid,
        body: formData
      });

      if (res.statusCode === 200) {
        setImageData(res.message);
        const currentMapping = [
          ...(getValues(`sections.${sectionIndex}.sectionPictureMapping`) || [])
        ];
        currentMapping[imageIndex] = {
          isCoverPicture: imageIndex === 0,
          pictureId: res.data.id,
          pictureUrl: res.message,
          locales: [
            { languageId: 1, pictureTitle: "" },
            { languageId: 2, pictureTitle: "" }
          ]
        };
        setValue(
          `sections.${sectionIndex}.sectionPictureMapping`,
          currentMapping
        );
        setValue(
          `sections.${sectionIndex}.sectionPictureMapping.${imageIndex}.locales`,
          [
            { languageId: 1, pictureTitle: "" },
            { languageId: 2, pictureTitle: "" }
          ],
          { shouldDirty: true }
        );
      }
    } catch (error) {
      console.error("Failed to upload image:", error);
    } finally {
      setLoadingUpload(false);
    }
  };

  const handleRemoveImage = () => {
    const currentMapping = [
      ...getValues(`sections.${sectionIndex}.sectionPictureMapping`)
    ];
    currentMapping.splice(imageIndex, 1);
    setValue(`sections.${sectionIndex}.sectionPictureMapping`, currentMapping);
    setImageData(undefined);
  };

  const can = useCan("media.picture.create");

  return (
    <div className="flex flex-col">
      {!imageData ? (
        <>
          {can ? (
            <label
              htmlFor={`file-upload-${sectionIndex}-${imageIndex}`}
              className={`border border-dashed items-center text-sm text-gray-600
                  w-full h-52 justify-center flex flex-col rounded-xl
                  ${
                    errors.sections?.[sectionIndex]?.sectionPictureMapping
                      ?.message && "border-red-500"
                  }
                  cursor-pointer hover:border-primary transition-colors duration-300`}
            >
              {loadingUpload ? (
                <span>
                  <LoaderIcon className="w-8 h-8" />
                </span>
              ) : (
                <span className="flex flex-col justify-center items-center">
                  <ArrowUpOnSquareIcon className="w-6 h-6 p-1" />
                  {`Ngarko foton ${imageIndex + 1}`}
                  <span className="text-xs mt-1">
                    Formatet e lejuara për foto janë png dhe jpg
                  </span>
                </span>
              )}
            </label>
          ) : (
            <div
              className="border-2 border-dashed items-center text-sm text-gray-600
                          h-52 w-full justify-center flex flex-col mb-4 rounded-xl
                           bg-gray-200 cursor-not-allowed transition-colors duration-300 px-4"
            >
              Nuk keni leje per te shtuar foto!
            </div>
          )}
          <Input
            {...register(
              `sections.${sectionIndex}.sectionPictureMapping.${imageIndex}`
            )}
            type="file"
            className="hidden"
            id={`file-upload-${sectionIndex}-${imageIndex}`}
            onChange={onChangeHandle}
            error={
              errors.sections?.[sectionIndex]?.sectionPictureMapping?.message
            }
          />
        </>
      ) : (
        <>
          <div className="relative w-full h-52 rounded-lg">
            <div className="w-full h-full border items-center flex justify-center rounded-lg">
              <img
                src={imageData}
                alt={`Image ${imageIndex + 1}`}
                className="object-contain rounded-lg h-44"
              />
            </div>
            <button
              type="button"
              className="text-primary font-bold hover:text-black absolute right-0 top-0"
              onClick={handleRemoveImage}
            >
              <XCircleIcon className="w-8 h-8 p-1" />
            </button>
          </div>

          <div className="mt-3 flex">
            {fields.map((field, idx) => (
              <div
                key={field.uuid}
                className={`w-full duration-500 transform transition-all
                  ${
                  langIdActive === field.languageId
                    ? "opacity-100"
                    : "opacity-0 w-0"
                }
                `}
              >
                <Input
                className="w-full"
                  {...register(
                    `sections.${sectionIndex}.sectionPictureMapping.${imageIndex}.locales.${idx}.pictureTitle`
                  )}
                  type="text"
                  placeholder="Enter picture title"
                  label="Picture Title"
                  error={
                    errors?.sections?.[sectionIndex]?.sectionPictureMapping?.[
                      imageIndex
                    ]?.locales?.[idx]?.pictureTitle?.message
                  }
                />
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default LinePageImage;
