/* eslint-disable max-lines */
import PlusIcon from "@heroicons/react/24/outline/PlusIcon";
import TrashIcon from "@heroicons/react/24/solid/TrashIcon";
import { useEffect, useState } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import useFetch from "../../../hooks/useFetch";
import { IQuestionAnswerLocalizedDto } from "../../../interfaces/endpoints/IFAQ";
import {
  createAnswer,
  createQuestion,
  deleteAnswer,
  getEntityAnswersWithLocales,
  updateAnswer,
  updateQuestion
} from "../../../services/FAQ.service";
import DeleteModal from "../../ui/DeleteModal";
import FormWrapper from "../../ui/FormWrapper";
import SpinnerLoader from "../../ui/SpinnerLoader";
import FAQCreator from "./FAQCreator";

const EditFAQ = () => {
  const { id } = useParams<{ id: string }>();
  const [selectedFAQ, setSelectedFAQ] =
    useState<IQuestionAnswerLocalizedDto | null>(null);

  const methods = useForm<IQuestionAnswerLocalizedDto>({
    defaultValues: {
      questionId: 0,
      answerId: 0,
      localizedQuestions: [
        { languageId: 1, question: "" },
        { languageId: 2, question: "" }
      ],
      localizedAnswers: [
        { languageId: 1, answer: "" },
        { languageId: 2, answer: "" }
      ]
    }
  });

  const { reset } = methods;
  const { response, refetch, loading } = useFetch(
    () => getEntityAnswersWithLocales(Number(id), 2),
    { initRequest: true }
  );

  useEffect(() => {
    if (selectedFAQ) {
      reset(selectedFAQ);
    } else {
      reset({
        questionId: 0,
        answerId: 0,
        localizedQuestions: [
          { languageId: 1, question: "" },
          { languageId: 2, question: "" }
        ],
        localizedAnswers: [
          { languageId: 1, answer: "" },
          { languageId: 2, answer: "" }
        ]
      });
    }
  }, [selectedFAQ, reset]);

  useEffect(() => {
    if (response?.data?.length && !selectedFAQ) {
      setSelectedFAQ(response.data[0]);
    }
  }, [response, selectedFAQ]);

  const onSubmit: SubmitHandler<IQuestionAnswerLocalizedDto> = async (data) => {
    try {
      if (data.questionId === 0) {
        // Create new FAQ
        const questionResponse = await createQuestion({
          questionFor: 2,
          locales: data.localizedQuestions
        });
        await createAnswer(questionResponse.data.id, Number(id), 2, {
          locales: data.localizedAnswers
        });
        toast.success("FAQ created successfully");
      } else {
        // Update existing FAQ
        await updateQuestion(data.questionId, {
          questionFor: 2,
          locales: data.localizedQuestions
        });
        await updateAnswer(data.answerId, {
          questionId: data.questionId,
          entityId: Number(id),
          entityType: 2,
          locales: data.localizedAnswers
        });
        toast.success("FAQ updated successfully");
      }
      refetch();
    } catch (error) {
      toast.error("Failed to save FAQ");
    }
  };

  const handleAddNewFAQ = () => {
    const newFAQ: IQuestionAnswerLocalizedDto = {
      questionId: 0,
      answerId: 0,
      localizedQuestions: [
        { languageId: 1, question: "" },
        { languageId: 2, question: "" }
      ],
      localizedAnswers: [
        { languageId: 1, answer: "" },
        { languageId: 2, answer: "" }
      ]
    };
    setSelectedFAQ(newFAQ);
  };

  return (
    <div className="flex gap-4">
      {/* Main editing area remains same */}
      <div className="flex-grow">
        <FormProvider {...methods}>
          <FormWrapper
            onSubmit={methods.handleSubmit(onSubmit)}
            title={`${selectedFAQ?.questionId ? "Edit" : "Create new"} FAQ`}
            showPrompt={
              !methods.formState.isSubmitSuccessful &&
              !!Object.keys(methods.formState.dirtyFields).length
            }
          >
            <div className="min-h-[200px] flex items-center justify-center">
              {loading ? (
                <SpinnerLoader className="text-primary" />
              ) : (
                <FAQCreator />
              )}
            </div>
          </FormWrapper>
        </FormProvider>
      </div>

      <div className="w-64 border pl-4">
        <div className="flex justify-between items-center mb-4 p-2">
          <h3 className="text-xl">Pyetjet</h3>
          <button
            type="button"
            className="p-1 rounded-full hover:bg-gray-100"
            onClick={handleAddNewFAQ}
          >
            <PlusIcon className="w-5 h-5" />
          </button>
        </div>
        <div className="space-y-2 max-h-[calc(100vh-200px)] overflow-y-auto">
          {response?.data?.map((faq, index) => (
            <div
              key={faq.questionId}
              className={`group p-2 rounded flex justify-between items-center hover:bg-gray-100 ${
                selectedFAQ?.questionId === faq.questionId ? "bg-gray-100" : ""
              }`}
            >
              <div
                className="cursor-pointer truncate flex-1"
                onClick={() => setSelectedFAQ(faq)}
              >
                {`${index + 1}) ${faq.localizedQuestions[0].question}`}
              </div>
              <DeleteModal
                params={faq.answerId}
                title="Konfirmo fshirjen e pyetjes"
                handleDelete={deleteAnswer}
                successMessage="Pyetja është fshirë me sukses"
                errorMessage="Ndodhi një gabim gjatë fshirjes"
                loadingMessage="Duke fshirë pyetjen..."
                onSuccess={() => {
                  if (selectedFAQ?.questionId === faq.questionId) {
                    setSelectedFAQ(null);
                  }
                  refetch();
                }}
                onError={refetch}
                indicator={
                  <button
                    type="button"
                    className="opacity-0 group-hover:opacity-100 p-1 hover:bg-red-100
                    rounded-full transition-opacity"
                  >
                    <TrashIcon className="w-4 h-4 text-red-500" />
                  </button>
                }
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default EditFAQ;
