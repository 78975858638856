/* eslint-disable max-lines */
/* eslint-disable max-len */
import { ChevronLeftIcon } from "@heroicons/react/24/outline";
import { yupResolver } from "@hookform/resolvers/yup";
import { format, parseISO } from "date-fns";
import { useEffect, useState } from "react";
import { DayPicker } from "react-day-picker";
import "react-day-picker/dist/style.css";
import { Controller, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import * as yup from "yup";
import FormWrapper from "../../../../components/ui/FormWrapper";
import Select from "../../../../components/ui/select/Select";
import SpinnerLoader from "../../../../components/ui/SpinnerLoader";
import { ICustomTripReservabilityForm } from "../../../../interfaces/endpoints/ITripReservability.types";
import { getCustomDatesForTrip, upsertCustomDates } from "../../../../services/TripReservability.service";
import { getAllTrips, getCurrentTrip } from "../../../../services/Trips.service";
import "./calendar.css";

const schema = yup.object().shape({
  tripId: yup.object().required("Orari është i detyrueshëm")
});

const ManageTripReservability: React.FC = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  const [selectedDates, setSelectedDates] = useState<Date[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [month, setMonth] = useState<Date>(new Date());

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { isSubmitSuccessful, dirtyFields, errors }
  } = useForm<ICustomTripReservabilityForm>({
    resolver: yupResolver(schema),
    defaultValues: {
      tripId: null,
      dates: []
    }
  });

  useEffect(() => {
    if (id) {
      const fetchData = async () => {
        setIsLoading(true);
        try {
          const [tripResponse, datesResponse] = await Promise.all([
            getCurrentTrip(Number(id)),
            getCustomDatesForTrip(Number(id))
          ]);

          const dates = datesResponse.data.map((date) => parseISO(date));
          setSelectedDates(dates);
          if (dates.length > 0) {
            setMonth(dates[0]);
          }
          reset({
            tripId: tripResponse.data,
            dates
          });
        } catch (error) {
          toast.error("Deshtoj ngarkimi i te dhenave");
          navigate("/trip-reservability",
            { state: ["Menaxhimi i linjave", "Datat specifike të rezervueshme"] });
        } finally {
          setIsLoading(false);
        }
      };
      void fetchData();
    }
  }, [id]);

  const onSubmit = async (data: ICustomTripReservabilityForm) => {
    try {
      if (!data.tripId) {
        throw new Error("Orari eshte i detyrueshem");
      }

      await upsertCustomDates({
        tripId: data.tripId.trip.id,
        dates: selectedDates.map((date) =>
          new Date(Date.UTC(
            date.getFullYear(),
            date.getMonth(),
            date.getDate(),
            12, 0, 0, 0
          )).toISOString()
        )
      });

      toast.success(`Datat u ${id ? "ndryshuan" : "krijuan"} me sukses`);
      setTimeout(() => {
        navigate("/trip-reservability");
      }, 500);
    } catch (error) {
      toast.error("Ruajtja e datave deshtoj");
    }
  };

  const handleDateSelect = (dates: Date[] | undefined) => {
    if (dates) {
      const utcDates = dates.map((date) =>
        new Date(Date.UTC(
          date.getFullYear(),
          date.getMonth(),
          date.getDate(),
          12, 0, 0, 0
        ))
      );
      setSelectedDates(utcDates);
      setValue("dates", utcDates, { shouldValidate: true });
    }
  };

  const handleDateRemove = (dateToRemove: Date) => {
    const updatedDates = selectedDates.filter(
      (date) => date.getTime() !== dateToRemove.getTime()
    );
    setSelectedDates(updatedDates);
    setValue("dates", updatedDates, { shouldValidate: true });
  };

  return (
    <>
      <Link
        to="/trip-reservability"
        state={["Menaxhimi i linjave", "Datat specifike të rezervueshme"]}
        className="flex items-center mb-4 text-primary"
      >
        <ChevronLeftIcon className="w-4 h-4 mr-2" />
        Kthehu tek lista
      </Link>

      {isLoading ? (
        <div className="mt-8 mb-4 gap-3 flex justify-center items-center">
          <p className="text-gray-500 text-center mr-4">Duke u ngarkuar... </p>
          <SpinnerLoader />
        </div>
      ) : (
        <FormWrapper
          title={`${id ? "Ndrysho" : "Shto"} datat e rezervueshme`}
          onSubmit={handleSubmit(onSubmit)}
          showPrompt={
            !isSubmitSuccessful &&
            !!Object.keys(dirtyFields).length
          }
          showSaveBtn={control._formValues.tripId}
        >
          <div className="flex flex-col gap-6">
            <Controller
              control={control}
              name="tripId"
              render={({ field: { onChange, value } }) => (
                <Select
                  label="Orari*"
                  placeholder="Zgjedh orarin"
                  selectedItem={value}
                  asyncFunc={getAllTrips}
                  onSelectedItemChange={onChange}
                  getOptionLabel={(option) => (
                    `${option.trip.id}:
                    ${option.routeName} - ${option.trip.departureTime.slice(0, 5)} -${option.trip.arrivalTime.slice(0, 5)}`
                  )}
                  itemToString={(option) =>
                    option ?
                    `${option.trip.id}: ${option.trip.departureTime.slice(0, 5)} - ${option.trip.arrivalTime.slice(0, 5)}`
                    : ""
                  }
                  error={errors.tripId?.message}
                  disabled={!!id}
                />
              )}
            />
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Datat e rezervueshme*
              </label>
              <div className="flex mb-2 justify-center">
                <DayPicker
                  mode="multiple"
                  selected={selectedDates}
                  onSelect={handleDateSelect}
                  className="border rounded-md p-2 bg-white custom-calendar"
                  fromDate={new Date()}
                  month={month}
                  onMonthChange={setMonth}
                />
                {errors.dates && (
                  <p className="text-red-500 text-sm mt-1">{errors.dates.message}</p>
                )}
              </div>
              {selectedDates.length > 0 && (
                <div className="mt-2">
                  <p className="text-sm font-medium text-gray-700 mb-1">Datat e zgjedhura:</p>
                  <div className="flex flex-wrap gap-2">
                    {selectedDates.map((date, index) => (
                      <span
                        key={index}
                        className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs
                        font-medium bg-[#fef7f5] text-blue-800"
                      >
                        {format(date, "dd/MM/yyyy")}
                        <button
                          type="button"
                          onClick={() => handleDateRemove(date)}
                          className="ml-1 inline-flex items-center p-0.5 text-blue-400 hover:text-blue-600"
                        >
                          ×
                        </button>
                      </span>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>
        </FormWrapper>
      )}
    </>
  );
};

export default ManageTripReservability;
