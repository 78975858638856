import axios from "axios";
import { IAxiosResponse } from "../interfaces/IAxiosResponse";

import { IAnswerInsertDto, IAnswerUpdateDto, IFAQAnswer, IQuestionAnswerLocalizedDto, IQuestionForModel, IQuestionModel } from "../interfaces/endpoints/IFAQ";

export const getQuestionTypes = async (): Promise<
  IAxiosResponse<IQuestionForModel[]>
> => {
  return axios.get<IQuestionForModel[]>(
    "/FrequentlyAskedQuestion/QuestionTypes"
  );
};

export const getEntityAnswers = async (
  entityId: number,
  entityType: number,
  page: number = 1,
  pageSize: number = 20
): Promise<IAxiosResponse<IFAQAnswer[]>> => {
  return axios.get<IFAQAnswer[]>(
    `/v1/FrequentlyAskedQuestion/Entities/${entityId}/` +
    `Answers?entityType=${entityType}&page=${page}&pageSize=${pageSize}`
  );
};

export const createAnswer = async (
  questionId: number,
  entityId: number,
  entityType: number,
  data: IAnswerInsertDto
): Promise<IAxiosResponse<IFAQAnswer>> => {
  return axios.post<IFAQAnswer>(
    `/v1/FrequentlyAskedQuestion/${questionId}/Entities/${entityId}/Answers?entityType=${entityType}`,
    data
  );
};

export const updateAnswer = async (
  answerId: number,
  data: IAnswerUpdateDto
): Promise<IAxiosResponse<number>> => {
  return axios.put<number>(
    `/v1/FrequentlyAskedQuestion/Answers/${answerId}`,
    data
  );
};

export const deleteAnswer = async (
  answerId: number
): Promise<IAxiosResponse<number>> => {
  return axios.delete<number>(`v1/FrequentlyAskedQuestion/Answers/${answerId}`);
};

export const getEntityAnswersWithLocales = async (
  entityId: number,
  entityType: number
): Promise<IAxiosResponse<IQuestionAnswerLocalizedDto[]>> => {
  return axios.get<IQuestionAnswerLocalizedDto[]>(
    `/v1/FrequentlyAskedQuestion/Entities/${entityId}/Answers?entityType=${entityType}`
  );
};

export const createQuestion = async (
  question: IQuestionModel
): Promise<IAxiosResponse<any>> => {
  return axios.post<any>("/v1/FrequentlyAskedQuestion/Questions", question);
};

export const updateQuestion = async (
  questionId: number,
  question: IQuestionModel
): Promise<IAxiosResponse<number>> => {
  return axios.put<number>(
    `/v1/FrequentlyAskedQuestion/Questions/${questionId}`,
    question
  );
};
