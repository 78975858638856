/* eslint-disable max-lines */
import { useEffect, useState } from "react";
import {
  FormProvider,
  SubmitHandler,
  useFieldArray,
  useForm
} from "react-hook-form";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import useFetch from "../../../hooks/useFetch";
import { ILinePageConfigCreateDto } from "../../../interfaces/endpoints/ILinePage";
import {
  getLinePageConfig,
  updateLinePageConfig
} from "../../../services/LinePages.service";
import Localizations from "../../bundles/Localizations";
import FormWrapper from "../../ui/FormWrapper";
import Input from "../../ui/input/Input";
import SpinnerLoader from "../../ui/SpinnerLoader";

const EditSEO = () => {
  const { id } = useParams<{ id: string }>();

  const methods = useForm<ILinePageConfigCreateDto>({
    defaultValues: {
      linePageId: Number(id),
      locales: [
        { languageId: 1, title: "", description: "", keywords: "" },
        { languageId: 2, title: "", description: "", keywords: "" }
      ],
      imageUrl: ""
    }
  });

  const {
    reset,
    handleSubmit,
    control,
    register,
    formState: { errors }
  } = methods;
  const { fields } = useFieldArray({
    control,
    name: "locales",
    keyName: "uuid"
  });

  const { response, loading, refetch } = useFetch(() => getLinePageConfig(Number(id)), {
    initRequest: true
  });

  const [langIdActive, setLangIdActive] = useState<1 | 2>(1);

  const onSubmit: SubmitHandler<ILinePageConfigCreateDto> = async (data) => {
    try {
      await updateLinePageConfig(Number(id), data);
      toast.success("SEO configuration updated successfully.", {
        position: "top-right"
      });
      refetch();
    } catch {
      toast.error("Failed to update SEO configuration.", {
        position: "top-right"
      });
    }
  };

  useEffect(() => {
    if (response?.data) {
      reset(response.data);
    }
  }, [response, reset]);

  return (
    <FormProvider {...methods}>
      <FormWrapper
        onSubmit={handleSubmit(onSubmit)}
        title="Edit SEO Configuration"
        showPrompt={
          !methods.formState.isSubmitSuccessful &&
          !!Object.keys(methods.formState.dirtyFields).length
        }
      >
        <div className="min-h-[350px] flex items-center justify-center">
          {loading ? (
            <SpinnerLoader className="text-primary" />
          ) : (
            <div className="flex flex-col w-full">
              <Input
                {...register("imageUrl")}
                label="Image URL"
                className="my-2"
                placeholder="Enter image URL... (will take default value if empty)"
                error={errors.imageUrl?.message}
              />
              <Localizations
                activeId={langIdActive}
                onChange={(langId) => setLangIdActive(langId)}
                alError={!!errors.locales?.[1]?.title}
                enError={!!errors.locales?.[0]?.title}
              />
              <div className="overflow-hidden">
                <div
                  className={`transform duration-500 transition-all w-full flex ${
                    langIdActive === 1 ? "translate-x-0" : "-translate-x-full"
                  }`}
                >
                  {fields.map((field, index) => (
                    <div
                      key={field.uuid}
                      className={`w-full flex-none p-[1px] duration-500 editor-container-${
                        field.languageId
                      } ${
                        langIdActive === field.languageId
                          ? "opacity-100"
                          : "opacity-0"
                      }`}
                    >
                      <Input
                        {...register(`locales.${index}.title`)}
                        label="Title"
                        className="my-2"
                        placeholder="Enter title...(will take default value if empty)"
                        error={errors.locales?.[index]?.title?.message}
                      />
                      <Input
                        {...register(`locales.${index}.description`)}
                        label="Description"
                        className="my-2"
                        placeholder="Enter description...(will take default value if empty)"
                        error={errors.locales?.[index]?.description?.message}
                      />
                      <Input
                        {...register(`locales.${index}.keywords`)}
                        label="Keywords"
                        className="my-2"
                        placeholder="Enter keywords...(will take default value if empty)"
                        error={errors.locales?.[index]?.keywords?.message}
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}
        </div>
      </FormWrapper>
    </FormProvider>
  );
};

export default EditSEO;
