import axios, { AxiosResponse } from "axios";
import { IGTFSLine, IGTFSLineDetailsResponse, IGTFSLineUpsertForm, IGTFSOperator, IGTFSOperatorResponse, IGTFSShape, IGTFSStation, IGTFSUploadResult } from "../interfaces/endpoints/IGTFS.types";
import { IAxiosResponse } from "../interfaces/IAxiosResponse";
import { IPagination } from "../interfaces/IPagination";
import { SelectParamsType } from "../interfaces/ISelectParams";
import { serialize } from "../utils/serializeToQueryString";

const BASE_URL = "v1/gtfs";
const GTFS_API = {
  SHAPES: `${BASE_URL}/shapes`,
  STATIONS: `${BASE_URL}/stops`,
  LINES: `${BASE_URL}/lines`,
  OPERATORS: `${BASE_URL}/agencies`,
  GENERATE_FEED: `${BASE_URL}/generate-feed`
};

export const getAllShapes = async ( params: SelectParamsType ): Promise<IAxiosResponse<IPagination<IGTFSShape>>> => {
  return axios.get(`${GTFS_API.SHAPES}${serialize(params)}`);
};

export const getShape = (id: number): Promise<AxiosResponse<IGTFSShape>> =>
  axios.get(`${GTFS_API.SHAPES}/${id}`);

export const createShape = (formData: FormData): Promise<AxiosResponse<IGTFSShape>> =>
  axios.post(`${GTFS_API.SHAPES}`, formData, {
    headers: {
      "Content-Type": "multipart/form-data"
    },
    validateStatus: (status) => (status >= 200 && status < 300) || status === 404 || status === 400
  });

export const deleteShape = (id: number): Promise<IAxiosResponse<boolean>> =>
  axios.delete(`${GTFS_API.SHAPES}/${id}`);

export const getShapes = async (params: SelectParamsType): Promise<IAxiosResponse<IPagination<IGTFSShape>>> => {
  return axios.get(`${GTFS_API.SHAPES}${serialize(params)}`);
};

export const updateShape = (id: number, formData: FormData): Promise<IAxiosResponse<IGTFSShape>> =>
  axios.put(`${GTFS_API.SHAPES}/${id}`, formData, {
    headers: {
      "Content-Type": "multipart/form-data"
    },
    validateStatus: (status) => (status >= 200 && status < 300) || status === 404 || status === 400
  });

export const updateGTFSStation = async (data: IGTFSStation): Promise<IAxiosResponse<IGTFSStation>> => {
  return axios.put(GTFS_API.STATIONS, data, {
    validateStatus: (status) => (status >= 200 && status < 300) || status === 404 || status === 400
  });
};

export const deleteGTFSStation = async (id: number): Promise<IAxiosResponse<boolean>>  => {
  return axios.delete(`${GTFS_API.STATIONS}/${id}`);
};

export const getGTFSStations = async ( params: SelectParamsType ): Promise<IAxiosResponse<IPagination<IGTFSStation>>> => {
  return axios.get(`${GTFS_API.STATIONS}${serialize(params)}`);
};

export const getGTFSStation = async (id: number): Promise<IAxiosResponse<IGTFSStation>> => {
  return axios.get(`${GTFS_API.STATIONS}/${id}`);
};

export const createGTFSStation = async (data: IGTFSStation): Promise<IAxiosResponse<IGTFSStation>> => {
  return axios.post(GTFS_API.STATIONS, data, {
    validateStatus: (status) => (status >= 200 && status < 300) || status === 404 || status === 400
  });
};

export const getLines = async (params: SelectParamsType): Promise<IAxiosResponse<IPagination<IGTFSLine>>> => {
  return axios.get(`${GTFS_API.LINES}${serialize(params)}`);
};

export const deleteLine = (id: number): Promise<AxiosResponse<boolean>> =>
  axios.delete(`${GTFS_API.LINES}/${id}`);

export const getShapesSelectList = async (params: SelectParamsType): Promise<IAxiosResponse<IPagination<{ id: number; name: string }>>> => {
  return axios.get(`${GTFS_API.SHAPES}${serialize(params)}`);
};

export const getLineDetails = async (lineId: number): Promise<IAxiosResponse<IGTFSLineDetailsResponse>> => {
  return axios.get(`${GTFS_API.LINES}/${lineId}`);
};

export const upsertGTFSLine = (data: IGTFSLineUpsertForm): Promise<IAxiosResponse<IGTFSLine>> =>
  axios.post(`${GTFS_API.LINES}`, data, {
    validateStatus: (status) => (status >= 200 && status < 300) || status === 404 || status === 400
  });

export const getOperators = async (params: SelectParamsType): Promise<IAxiosResponse<IGTFSOperatorResponse>> => {
  return axios.get(`${GTFS_API.OPERATORS}${serialize(params)}`);
};

export const getOperator = async (id: number): Promise<IAxiosResponse<IGTFSOperator>> => {
  return axios.get(`${GTFS_API.OPERATORS}/${id}`);
};

export const updateOperator = async (data: IGTFSOperator): Promise<IAxiosResponse<IGTFSOperator>> => {
  return axios.put(GTFS_API.OPERATORS, data, {
    validateStatus: (status) => (status >= 200 && status < 300) || status === 404 || status === 400
  });
};
export const generateGTFSFeed = async (): Promise<IAxiosResponse<IGTFSUploadResult>> => {
  return axios.get(GTFS_API.GENERATE_FEED, {
    validateStatus: (status) => (status >= 200 && status < 300) || status === 404 || status === 400
  });
};
