import { PencilSquareIcon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";
import HeaderPage from "../../../components/lines-managements/header/HeaderPage";
import DeleteModal from "../../../components/ui/DeleteModal";
import Table, { Row } from "../../../components/ui/table";
import Pagination from "../../../components/ui/table/Pagination";
import Can from "../../../components/users/permissions/Can";
import useCan from "../../../components/users/permissions/useCan";
import usePageIndex from "../../../hooks/usePageIndex";
import { useSearch } from "../../../hooks/useSearch";
import { IGTFSShape } from "../../../interfaces/endpoints/IGTFS.types";
import { deleteShape, getAllShapes } from "../../../services/GTFS.service";

const COLUMNS: (string | number)[] = [
  "Id",
  "Emri",
  "Shape Id",
  // "Gjerësia gjeografike startuese",
  // "Gjatësia gjeografike startuese",
  // "Gjerësia gjeografike përfundimtare",
  // "Gjatësia gjeografike përfundimtare",
  "Veprimet"
];

const PAGE_SIZE = 10;

const GTFSShapes: React.FC = () => {
  const can = useCan("gtfs.create");
  const { response, loading, handlePageChange, page, setResponse } =
    useSearch(getAllShapes, {
      withQuerySearch: true
    });

  const { pageIndex } = usePageIndex();

  return (
    <>
      <HeaderPage
        {...(can && {
          link: {
            to: "create",
            children: "Shto shape te re",
            state: ["Menaxhimi i GTFS", "Shapes", "Menaxho"]
          }
        })}
      />
      <div className="flex flex-col" style={{ height: "calc(100vh - 11rem)" }}>
        <Table titles={COLUMNS} style="hover" loading={loading}>
          {!loading && response?.data?.result?.length ? (
            response.data.result.map((row: IGTFSShape) => (
              <Row
                key={row.id}
                id={row.id}
                name={row.name}
                shapeId={row.shapeId}
                // startingLatitude={row.startingLatitude.toFixed(6)}
                // startingLongitude={row.startingLongitude.toFixed(6)}
                // endingLatitude={row.endingLatitude.toFixed(6)}
                // endingLongitude={row.endingLongitude.toFixed(6)}
                actions={
                  !row.shapeId.endsWith("-reverse") &&
                  <span className="flex items-center">
                    <Can permissions="gtfs.update">
                      <Link
                        className="p-2.5 md:p-1"
                        state={{ crumbs: ["Menaxhimi i GTFS", "Shapes", "Ndrysho Shape"], page: pageIndex }}
                        to={`edit/${row.id}`}
                      >
                        <PencilSquareIcon className="w-5 h-5 md:w-4" />
                      </Link>
                    </Can>
                    <Can permissions="gtfs.delete">
                      <DeleteModal
                        params={row.id}
                        title="Confirm shape deletion"
                        handleDelete={deleteShape}
                        successMessage="Shape është fshire me sukses."
                        errorMessage="Fshirja e shape dështoj."
                        loadingMessage="Shape është duke u fshire..."
                        onSuccess={() => {
                            setResponse((prev) =>
                            prev ? {
                              ...prev,
                              data: {
                              ...prev.data,
                              result: prev.data.result.filter(
                                (el) => el.id !== row.id && el.shapeId !== (row.shapeId + "-reverse")
                              )
                              }
                            } : null
                            );
                        }}
                      />
                    </Can>
                  </span>
                }
              />
            ))
          ) : (
            <tr>
              <td colSpan={COLUMNS.length} className="text-center py-4">
                {loading ? "Loading..." : "No shapes found"}
              </td>
            </tr>
          )}
        </Table>
        <Pagination
          onPageChange={handlePageChange}
          totalCount={response?.data?.totalCount || 0}
          length={response?.data?.result?.length || 0}
          page={page}
          pageSize={PAGE_SIZE}
        />
      </div>
    </>
  );
};

export default GTFSShapes;
