import { useCallback, useEffect, useState } from "react";
import { IAxiosResponse } from "../interfaces/IAxiosResponse";
import {
  apiFuncType,
  errorType,
  fetchConfigType,
  loadingType,
  responseType
} from "../interfaces/hooks/IFetch";

const defaultConfig = {
  initRequest: false,
  resetResponseOnError: false,
  loading: false,
  params: {},
  updateLoadingWhenSuccess: true
};

const useFetch = <T, D = undefined>(
  apiFunc: apiFuncType<T, D>,
  config?: fetchConfigType<D>
) => {
  const {
    initRequest,
    resetResponseOnError,
    loading: _loading,
    updateLoadingWhenSuccess
  } = {
    initRequest:
      typeof config?.initRequest === "boolean"
        ? config.initRequest
        : defaultConfig.initRequest,
    loading:
      typeof config?.loading === "boolean"
        ? config.loading
        : defaultConfig.loading,
    resetResponseOnError:
      typeof config?.resetResponseOnError === "boolean"
        ? config.resetResponseOnError
        : defaultConfig.resetResponseOnError,
    updateLoadingWhenSuccess:
      typeof config?.updateLoadingWhenSuccess === "boolean"
        ? config.updateLoadingWhenSuccess
        : defaultConfig.updateLoadingWhenSuccess
  };

  const [response, setResponse] =
    useState<responseType<IAxiosResponse<T>>>(null);
  const [loading, setLoading] = useState<loadingType>(
    !!initRequest || !!_loading
  );
  const [error, setError] = useState<errorType>(null);

  // Handle Func with useCallback to handle all state of request
  const handleFunc = useCallback(
    async (params: D): Promise<responseType<IAxiosResponse<T>>> => {
      setLoading(true);
      try {
        let temp = {} as D;
        if (params) temp = params;
        const res = await apiFunc(temp);
        if (updateLoadingWhenSuccess) setLoading(false);
        setResponse(res);
        setError(null);
        // return res on 'then'
        return res;
      } catch (err) {
        setError(err);
        setLoading(false);
        if (resetResponseOnError) setResponse(null);
        // return error on 'catch'
        // here to handle all errors
        return { data: {} as T, status: 0, statusText: "" };
      }
    },
    []
  );

  const refetch = () => {
      let temp = {} as D;
      if (config?.params) temp = config.params as D;
      void handleFunc(temp);
  };

  useEffect(() => {
    if (initRequest) {
      let temp = {} as D;
      if (config?.params) temp = config.params;
      void handleFunc(temp);
    }
  }, [handleFunc, initRequest, JSON.stringify(config?.params)]);

  return {
    response,
    loading,
    handleFunc,
    error,
    setResponse,
    setError,
    refetch,
    setLoading
  } as const;
};

export default useFetch;
