/* eslint-disable max-len */
import { FC } from "react";
import { IIcon } from "./IIcon";

const DoubleImageContent: FC<IIcon> = ({ color, size }) => {
  const currentColor = color || "#B3B3B3";
  const currentSize = size || 40;

  return (
    <span>
      <svg
        width={currentSize}
        height={currentSize}
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="w-auto h-auto"
      >
        <rect width="40" height="40" rx="6" fill="white" />
        <rect x="8" y="8" width="11" height="12" rx="2" fill={currentColor} />
        <rect x="21" y="8" width="11" height="12" rx="2" fill={currentColor} />
        <path
          d="M10 24L30 24"
          stroke={currentColor}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10 28L30 28"
          stroke={currentColor}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10 30L30 30"
          stroke={currentColor}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10 32L30 32"
          stroke={currentColor}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </span>
  );
};

export default DoubleImageContent;
